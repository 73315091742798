<app-columnone>
    <!-- user profile -->
    <div class="container">
        <div class="row flex-lg-nowrap">
            <div class="col">
                <div class="row">
                    <div class="col mb-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="e-profile">
                                    <div class="row">
                                        <div class="col-12 col-sm-auto">
                                            <div class="mx-auto" style="width: 120px;">
                                                <div class="d-flex justify-content-center align-items-center rounded">
                                                    <img class="rounded" height="135" width="135" src="{{user?.profileImageUrl}}" alt="">
                                                </div>
                                                <div *ngIf="fileStatus?.status==='progress'" class="progress mt-1">
                                                    <div class="progress-bar bg-info" role="progressbar" [style.width.%]="fileStatus?.percentage" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">{{fileStatus?.percentage}}%</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col d-flex flex-column flex-sm-row justify-content-between mb-3">
                                            <div class="text-left text-sm-left mb-2 mb-sm-0">
                                                <h4 class="pt-sm-2 pb-1 mb-0 text-nowrap">{{user?.firstName}} {{user?.lastName}} ( {{user?.username}} )</h4>
                                                <div *ngIf="user?.lastLoginDateDisplay !== null" class="text-muted"><small>Dernière connexion:
                                                    {{user?.lastLoginDateDisplay | date:'medium'}}</small></div>
                                                <div class="mt-2">
                                                    <button (click)="updateProfileImage()" class="btn btn-green" type="button">
                                                        <i class="fa fa-fw fa-camera"></i>
                                                        <span> Changer la photo</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="text-center text-sm-right">
                                                <div class="text-muted"><small>A rejoint le {{user?.joinDate | date:'mediumDate'}}</small></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-content pt-3">
                                        <div class="tab-pane active">
                                            <form #profileUserForm="ngForm" (ngSubmit)="onUpdateCurrentUser(profileUserForm.value)" class="form" novalidate>
                                                <div class="row">
                                                    <div class="col">
                                                        <div class="row">
                                                            <div class="col">
                                                                <div class="form-group">
                                                                    <label>Prénom</label>
                                                                    <input readonly="true" type="text" name="firstName" required [(ngModel)]="user.firstName" class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="form-group">
                                                                    <label>Nom</label>
                                                                    <input readonly="true" type="text" name="lastName" required [(ngModel)]="user.lastName" class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <div class="form-group">
                                                                    <label>Nom d'utilisateur</label>
                                                                    <input readonly="true" type="text" name="username" required [(ngModel)]="user.username" class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="form-group">
                                                                    <label>Email</label>
                                                                    <input readonly="true" type="text" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name="email" required [(ngModel)]="user.email" class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <div class="form-group">
                                                                    <label>Adresse du domicile</label>
                                                                    <input type="text" name="homeAddress" required [(ngModel)]="user.homeAddress" class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="form-group">
                                                                    <label>Numéro de téléphone</label>
                                                                    <input type="text" (keypress)="keyPress($event)" name="phoneNumber" required [(ngModel)]="user.phoneNumber" class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <div class="form-group">
                                                                    <label>Status matrimonial</label>
                                                                    <select name="statusMarital" required [(ngModel)]="user.statusMarital" class="form-select form-select-sm">
                                                                        <option disabled value="single">Célibataire</option>
                                                                        <option disabled value="married">Marié(e)</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="form-group">
                                                                    <label>Numéro CIN</label>
                                                                    <input readonly="true" type="text" name="cin" required [(ngModel)]="user.cin" class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col d-flex justify-content-end">
                                                        <button class="btn btn-green" type="submit">
                                                            <i *ngIf="refreshing" class="fa fa-spinner fa-spin"></i>
                                                            <i *ngIf="!refreshing" class="fa fa-floppy-o"></i>&nbsp;&nbsp;
                                                            <span *ngIf="refreshing">Changement...</span>
                                                            <span *ngIf="!refreshing">Enregistrer les changements</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-12 col-md-3 mb-3">
                        <div class="card mb-3">
                            <div class="card-body">
                                <div class="px-xl-3">
                                    <button (click)="onLogOut()" class="btn btn-block btn-secondary">
                                        <span> Se déconnecter </span>
                                        <i class="fa fa-sign-out"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- profile image change form -->
    <form enctype="multipart/form-data" style="display:none;">
        <input type="file" (change)="onProfileImageChange($event); onUpdateProfileImage()" name="profile-image-input" id="profile-image-input" placeholder="file" accept="image/*" />
    </form>
</app-columnone>