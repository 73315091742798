<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Réinitialiser votre mot de passe</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid h-100">
        <div class="d-flex h-100 p-v-15 flex-column justify-content-between">
            <div class="container">
                <div class="row align-items-center">
                    <form nz-form #resetPasswordForm="ngForm" (ngSubmit)="onResetPassword(resetPasswordForm)" nzLayout="vertical" class="login-form">
                        <nz-form-item>
                            <nz-form-label nzRequired nzFor="email">Entrez votre adresse email pour récupérer votre mot de passe</nz-form-label>
                            <nz-form-control nzErrorTip="The input is not valid email!">
                                <input type="email" nz-input name="reset-password-email" ngModel placeholder="example@gmail.com">
                            </nz-form-control>
                        </nz-form-item>
                        <button type="submit" style="display: none;" id="reset-password-save" nz-button></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Annuler</button>
    <button type="button" class="btn btn-green" [disabled]="resetPasswordForm.invalid" (click)="saveResetPaswword()">Récupérer</button>
</div>