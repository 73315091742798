import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';

@Injectable({providedIn: 'root'})
export class ExportAsExcelFileService {
  private host = environment.apiUrl;

  constructor(private http: HttpClient) {}

  static toExportFileName(): string {
    return `export_${new Date()}.xlsx`;
  }

  public exportAsExcelFile(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, ExportAsExcelFileService.toExportFileName());
  }

}
