import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { City } from 'src/app/shared/model/city';
import { Country } from 'src/app/shared/model/country';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { ResourceService } from 'src/app/shared/service/resource.service';

@Component({
  selector: 'app-addresource',
  templateUrl: './addresource.component.html',
  styleUrls: ['./addresource.component.css']
})
export class AddresourceComponent implements OnInit, OnDestroy {
  @Input() type: string;
  @Input() countries: Country[];
  private subscriptions: Subscription[] = [];
  public isCountry: boolean;
  public isCity: boolean;
  public countrySelected: FormControl = new FormControl();

  constructor(public modal: NgbActiveModal, private resourceService: ResourceService, private notificationService: NotificationService) { }

  ngOnInit(): void {
    if (this.type == "Pays") {
      this.isCountry = true;
    }
    if (this.type == "Ville") {
      this.isCity = true;
    }
  }

  public saveNewCountry(): void {
    this.clickButton('new-country-save');
  }

  onAddNewCountry(countryForm: NgForm) {
    const formData = this.resourceService.createCountryFormData(null, countryForm.value);
    this.subscriptions.push(
      this.resourceService.addCountry(formData).subscribe(
        (response: Country) => {
          countryForm.reset();
          this.sendNotification(NotificationType.SUCCESS, `${response.name} added successfully`);
          this.modal.close('Ok click');
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        }
      )
    );
  }

  public saveNewCity(): void {
    this.clickButton('new-city-save');
  }

  onAddNewCity(cityForm: NgForm) {
    const formData = this.resourceService.createCityFormData(null, cityForm.value);
    formData.append('countryName', this.countrySelected.value);
    this.subscriptions.push(
      this.resourceService.addCity(formData).subscribe(
        (response: City) => {
          cityForm.reset();
          this.sendNotification(NotificationType.SUCCESS, `${response.name} added successfully`);
          this.modal.close('Ok click');
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        }
      )
    );
  }
  
  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  private clickButton(buttonId: string): void {
    document.getElementById(buttonId).click();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
