import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { AdministraveRequest } from 'src/app/shared/model/administrativerequest';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { PersonnelService } from 'src/app/shared/service/personnel.service';

@Component({
  selector: 'app-editadministrativerequest',
  templateUrl: './editadministrativerequest.component.html',
  styleUrls: ['./editadministrativerequest.component.css']
})
export class EditadministrativerequestComponent implements OnInit, OnDestroy {
  @Input() editRequest: AdministraveRequest;
  @Input() currentId: string;
  private subscriptions: Subscription[] = [];
  public refreshing: boolean;

  constructor(public activeModal: NgbActiveModal, private personnelService: PersonnelService, private notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  public onUpdateAdministrativeRequest(): void {
    const formData = new FormData();
    formData.append('currentId', JSON.stringify(this.currentId));
    formData.append('status', this.editRequest.status);
    this.subscriptions.push(
      this.personnelService.updateAdministraveRequest(formData).subscribe(
        (response: AdministraveRequest) => {
          this.sendNotification(NotificationType.SUCCESS, `This request updated successfully`);
          this.activeModal.close('Ok click');
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        }
      )
    );
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
