import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectionStrategy, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isSameDay, isSameMonth, startOfDay, addDays, subDays, addHours, endOfMonth } from 'date-fns';
import { Subject } from 'rxjs';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { Subscription } from 'rxjs';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { HolidayService } from 'src/app/shared/service/holiday.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EventColor } from 'calendar-utils';
import { Resizable } from 'src/app/shared/model/resizable';
import { DatePipe } from '@angular/common';
import { AddcalendareventComponent } from '../modals/addcalendarevent/addcalendarevent.component';
import { DeletecalendareventComponent } from '../modals/deletecalendarevent/deletecalendarevent.component';
import { Router } from '@angular/router';
import { EditcalendareventComponent } from '../modals/editcalendarevent/editcalendarevent.component';
import { CustomHttpRespone } from 'src/app/shared/model/custom-http-response';
import { AddholidaytypeComponent } from '../modals/addholidaytype/addholidaytype.component';
import { HolidayType } from 'src/app/shared/model/holidaytype';
import { UpdateholidaytypeComponent } from '../modals/updateholidaytype/updateholidaytype.component';
import { DeleteholidaytypeComponent } from '../modals/deleteholidaytype/deleteholidaytype.component';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-politics',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './politics.component.html',
  styleUrls: ['./politics.component.css']
})
export class PoliticsComponent implements OnInit, OnDestroy {
  public refreshing: boolean;
  public holidayTypes: HolidayType[];
  private subscriptions: Subscription[] = [];
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  public view: CalendarView = CalendarView.Month;
  public CalendarView = CalendarView;
  public viewDate: Date = new Date();
  public modalData: {
    action: string;
    event: CalendarEvent;
  };
  public actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.editEvent(event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.deleteEvent(event);
      },
    },
  ];
  public refresh = new Subject<void>();
  public events: Array<CalendarEvent> = [];
  // public events: CalendarEvent[] = [
  //   {
  //     start: subDays(startOfDay(new Date()), 1),
  //     end: addDays(new Date(), 1),
  //     title: 'A 3 day event',
  //     color: colors.red,
  //     actions: this.actions,
  //     allDay: true,
  //     resizable: {
  //       beforeStart: true,
  //       afterEnd: true,
  //     },
  //     draggable: true,
  //   },
  //   {
  //     start: startOfDay(new Date()),
  //     title: 'An event with no end date',
  //     color: colors.yellow,
  //     actions: this.actions,
  //   },
  //   {
  //     start: subDays(endOfMonth(new Date()), 3),
  //     end: addDays(endOfMonth(new Date()), 3),
  //     title: 'A long event that spans 2 months',
  //     color: colors.blue,
  //     allDay: true,
  //   },
  //   {
  //     start: addHours(startOfDay(new Date()), 2),
  //     end: addHours(new Date(), 2),
  //     title: 'A draggable and resizable event',
  //     color: colors.yellow,
  //     actions: this.actions,
  //     resizable: {
  //       beforeStart: true,
  //       afterEnd: true,
  //     },
  //     draggable: true,
  //   },
  // ];
  public activeDayIsOpen: boolean = false;

  constructor(private holidayService: HolidayService, private modal: NgbModal, public datepipe: DatePipe, private router: Router,
    private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.getCalendarEvents(false);
    this.getHolidayTypes(false);
  }

  public getCalendarEvents(showNotification: boolean): void {
    this.refreshing = true;
    this.subscriptions.push(
      this.holidayService.getCalendarEvents().subscribe(
        (response: CalendarEvent[]) => {
          this.events = [];
          for (let i = 0; i < response.length; i++) {
            let eventsObj = {} as CalendarEvent;
            let colorObj = {} as EventColor;
            eventsObj.start = new Date(response[i]['start']);
            eventsObj.end = new Date(response[i]['end']);
            eventsObj.title = response[i]['title'];
            colorObj.primary = response[0]['colorPrimary'];
            colorObj.secondary = response[0]['colorSecondary'];
            eventsObj.color = colorObj;
            eventsObj.actions = this.actions;
            eventsObj.allDay = true;
            eventsObj.draggable = true;
            eventsObj.resizable = new Resizable();
            this.events.push(eventsObj);
          }
          this.refreshing = false;
          if (showNotification) {
            this.sendNotification(NotificationType.SUCCESS, `${response.length} calendarEvent(s) loaded successfully.`);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.refreshing = false;
        }
      )
    );
  }

  public getHolidayTypes(showNotification: boolean): void {
    this.refreshing = true;
    this.subscriptions.push(
      this.holidayService.getHolidayTypes().subscribe(
        (response: HolidayType[]) => {
          this.holidayTypes = response;
          this.refreshing = false;
          if (showNotification) {
            this.sendNotification(NotificationType.SUCCESS, `${response.length} user(s) loaded successfully.`);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.refreshing = false;
        }
      )
    );

  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    // this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    const modalRef = this.modal.open(AddcalendareventComponent);
    modalRef.result.then(
      () => {
        // this.router.navigateByUrl('/controlpanel/politics');
        this.getCalendarEvents(false);
      },
      () => {
      });
  }

  editEvent(eventToEdit: CalendarEvent) {
    this.holidayService.getCalendarEvents().subscribe(
      (response: CalendarEvent[]) => {
        for (let i = 0; i < response.length; i++) {
          if ((eventToEdit.title == response[i]['title']) &&
            (this.datepipe.transform(eventToEdit.start, 'dd/MM/yyyy') == this.datepipe.transform(response[i]['start'], 'dd/MM/yyyy')) &&
            (this.datepipe.transform(eventToEdit.end, 'dd/MM/yyyy') == this.datepipe.transform(response[i]['end'], 'dd/MM/yyyy'))) {
            let calendarEventId = Number(response[i]['id']);
            const modalRef = this.modal.open(EditcalendareventComponent);
            modalRef.componentInstance.editCalendarEvent = eventToEdit;
            modalRef.componentInstance.currentId = calendarEventId;
            modalRef.result.then(
              () => {
                // this.router.navigateByUrl('/controlpanel/politics');
                this.getCalendarEvents(false);
              },
              () => {
              });
          }
        }
      },
      (errorResponse: HttpErrorResponse) => {
        this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
      }
    )
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    const modalRef = this.modal.open(DeletecalendareventComponent);
    modalRef.componentInstance.eventToDelete = eventToDelete;
    modalRef.componentInstance.title = eventToDelete.title;
    modalRef.result.then(
      () => {
        // this.router.navigateByUrl('/controlpanel/politics');
        this.getCalendarEvents(false);
      },
      () => {
      });
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  public createNewHolidayType() {
    const modalRef = this.modal.open(AddholidaytypeComponent);
    modalRef.result.then(
      () => {
        this.getHolidayTypes(false);
      },
      () => {
      });
  }

  public editHolidayType(holidayType: HolidayType) {
    const modalRef = this.modal.open(UpdateholidaytypeComponent);
    modalRef.componentInstance.editHolidayType = holidayType;
    modalRef.componentInstance.currentName = holidayType.name;
    modalRef.result.then(
      () => {
        this.getHolidayTypes(false);
      },
      () => {
      });
  }

  public deleteHolidayType(holidayTypeName: string) {
    const modalRef = this.modal.open(DeleteholidaytypeComponent);
    modalRef.componentInstance.name = holidayTypeName;
    modalRef.result.then(
      () => {
        this.getHolidayTypes(false);
      },
      () => {
      });
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
