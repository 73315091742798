import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { NotificationService } from '../service/notification.service';
import { NotificationType } from '../enum/notification-type.enum';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard implements CanActivate {

  constructor(private authenticationService: AuthenticationService, private router: Router,
    private notificationService: NotificationService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.isUserLoggedIn(next);
  }

  private isUserLoggedIn(route: ActivatedRouteSnapshot): boolean {
    let roles = route.data["role"] as Array<string>;
    if (this.authenticationService.isUserLoggedIn()) {
      const userRole = this.authenticationService.getUserFromLocalCache().role;
      if (roles && roles.indexOf(userRole) === -1) {
        this.router.navigate(['/login']);
        // this.notificationService.notify(NotificationType.ERROR, `You need to log in to access this page`);
        return false;
      }
      return true;
    }
    this.router.navigate(['/login']);
    this.notificationService.notify(NotificationType.ERROR, `You need to log in to access this page`);
    return false;
  }

}
