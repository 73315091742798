<app-columnone>
    <!-- Tables Project -->
    <div class="m-3">
        <div nz-row [nzGutter]="8">
            <!-- All Reports  -->
            <div nz-col [nzSpan]="12">
                <nz-card class="border border-2 overflow-auto" style="height: 625px;">
                    <div class="m-4">
                        <p class="font-weight-bold" style="font-size:1.2vw;">Historique des comptes rendus</p>
                        <table mat-table [dataSource]="dataSource">

                            <ng-container matColumnDef="title">
                                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                <td mat-cell *matCellDef="let row" style="width: 60%;"> {{ row.title }} </td>
                            </ng-container>

                            <ng-container matColumnDef="dateFrom">
                                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                <td mat-cell *matCellDef="let row" style="width: 15%;"> {{ row.dateFrom | date:"MM.dd.yy" }} </td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                <td mat-cell *matCellDef="let i = index" style="width: 20%;">
                                    <i class="fa fa-edit btn btn-outline-secondary" (click)="onEditReport(i)"></i>
                                    <i class="fa fa-trash btn btn-outline-secondary ml-1" (click)="onDeleteReport(i)"></i>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </nz-card>
            </div>
            <!-- Add New Report -->
            <div nz-col [nzSpan]="12" [hidden]="!isAddReport">
                <nz-card class="border border-2 overflow-auto" style="height: 625px;">
                    <div class="m-4">
                        <p class="font-weight-bold" style="font-size:1.2vw;">Ajouter un compte rendu</p>
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <label for="title">Titre</label>
                                <input formControlName="title" id="title" type="text" class="form-control form-control-sm">
                                <div *ngIf="f['title'].touched && f['title'].invalid" class="alert alert-danger">
                                    <div *ngIf="f['title'].errors && f['title'].errors['required']">Titre est obligatoire.</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="place">Lieu</label>
                                <input formControlName="place" id="place" type="text" class="form-control form-control-sm">
                                <div *ngIf="f['place'].touched && f['place'].invalid" class="alert alert-danger">
                                    <div *ngIf="f['place'].errors && f['place'].errors['required']">Lieu est obligatoire.</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="dateFrom">Commence à</label>
                                <input type="text" mwlFlatpickr formControlName="dateFrom" id="dateFrom" [altInput]="true" [convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Pas encore défini" class="form-control form-control-sm"
                                />
                                <div *ngIf="f['dateFrom'].touched && f['dateFrom'].invalid" class="alert alert-danger">
                                    <div *ngIf="f['dateFrom'].errors && f['dateFrom'].errors['required']">Date est obligatoire.</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="dateTo">Fini à</label>
                                <input type="text" mwlFlatpickr formControlName="dateTo" id="dateTo" [altInput]="true" [convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Pas encore défini" class="form-control form-control-sm" />
                                <div *ngIf="f['dateTo'].touched && f['dateTo'].invalid" class="alert alert-danger">
                                    <div *ngIf="f['dateTo'].errors && f['dateTo'].errors['required']">Date est obligatoire.</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="participantUsernames">Participants</label>
                                <nz-select nzMode="multiple" nzPlaceHolder="Please select" [nzOptions]="participants" [(ngModel)]="listOfSelectedValue" [ngModelOptions]="{standalone: true}"></nz-select>
                            </div>
                            <div class="form-group">
                                <label for="reportDocument">Importer le compte rendu de la réunion</label>
                                <div class="custom-file">
                                    <input type="file" (change)="showData($event)" class="custom-file-input" (change)="onReportDocumentChange($event)" formControlName="reportDocument" id="reportDocument" accept=".pdf">
                                    <label class="custom-file-label">
                                            <span [hidden]="!fileName">{{fileName}}</span>
                                            <span [hidden]="fileName">Choisir un fichier</span>
                                        </label>
                                    <div *ngIf="f['reportDocument'].touched && f['reportDocument'].invalid" class="alert alert-danger">
                                        <div *ngIf="f['reportDocument'].errors && f['reportDocument'].errors['required']">Importer le document d'approbation signé est obligatoire.</div>
                                    </div>
                                </div>
                                <p class="mt-2" [hidden]="!fileName"><strong>Aperçu de document importé :</strong></p>
                                <object [hidden]="!fileName" #pdfview [data]='' type="application/pdf"></object>
                            </div>
                            <div class="col d-flex justify-content-end">
                                <button class="btn btn-green" type="submit">
                                        <i *ngIf="refreshing" class="fa fa-spinner fa-spin"></i>
                                        <i *ngIf="!refreshing" class="fa fa-floppy-o"></i>&nbsp;&nbsp;
                                        <span *ngIf="refreshing">Chargement...</span>
                                        <span *ngIf="!refreshing">Créer</span>
                                    </button>
                            </div>
                        </form>
                    </div>
                </nz-card>
            </div>
            <!-- Update Report -->
            <div nz-col [nzSpan]="12" [hidden]="!isEditReport">
                <nz-card class="border border-2 overflow-auto" style="height: 625px;">
                    <div class="m-4">
                        <div nz-col nzSpan="12" class="mt-2" style="display: flex;">
                            <i nz-icon nzTooltipTitle="Cliquez pour revenir" nzTooltipPlacement="top" [nzTooltipColor]="color" class="mt-1" style="font-size:1.2vw;" nzType="menu-fold" nzTheme="outline" nz-tooltip (click)="backToMenu()"></i>
                            <p class="font-weight-bold ml-2" style="font-size:1.2vw;">Éditer {{ editReport.title }}</p>
                        </div>
                        <form #editReportForm="ngForm" (ngSubmit)="onUpdate()">
                            <div class="form-group">
                                <label for="title">Titre</label>
                                <input [(ngModel)]="editReport.title" name="title" type="text" class="form-control form-control-sm">
                            </div>
                            <div class="form-group">
                                <label for="place">Lieu</label>
                                <input [(ngModel)]="editReport.place" name="place" type="text" class="form-control form-control-sm">
                            </div>
                            <div class="form-group">
                                <label for="dateFrom">Commence à</label>
                                <input type="text" mwlFlatpickr [(ngModel)]="editReport.dateFrom" name="dateFrom" [altInput]="true" [convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Pas encore défini" class="form-control form-control-sm"
                                />
                            </div>
                            <div class="form-group">
                                <label for="dateTo">Fini à</label>
                                <input type="text" mwlFlatpickr [(ngModel)]="editReport.dateTo" name="dateTo" [altInput]="true" [convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Pas encore défini" class="form-control form-control-sm"
                                />
                            </div>
                            <div class="form-group">
                                <label for="participantUsernames">Participants</label>
                                <nz-select nzMode="multiple" nzPlaceHolder="Please select" [nzOptions]="participants" [(ngModel)]="listOfEditSelectedValue" [ngModelOptions]="{standalone: true}"></nz-select>
                            </div>
                            <div class="form-group">
                                <a style="color: #8FD300;" (click)="downloadFile(editReport.reportId)">Cliquez ici pour télécharger l'ancien compte rendu de la réunion</a>
                            </div>
                            <div class="form-group">
                                <label for="editReportdocument">Importer le compte rendu de la réunion</label>
                                <div class="custom-file">
                                    <input type="file" (change)="editShowData($event)" class="custom-file-input" (change)="onEditReportDocumentChange($event)" name="editReportdocument" accept=".pdf">
                                    <label class="custom-file-label">
                                        <span [hidden]="!editFileName">{{editFileName}}</span>
                                        <span [hidden]="editFileName">Choisir un fichier</span>
                                        </label>
                                </div>
                                <!-- <p class="mt-2" [hidden]="!editFileName"><strong>Aperçu de document importé :</strong></p>
                                <object #pdfViewer [data]='' type="application/pdf"></object> -->
                            </div>
                            <div class="col d-flex justify-content-end">
                                <button class="btn btn-green" [disabled]="editReportForm.invalid" type="submit">
                                    <i *ngIf="refreshing" class="fa fa-spinner fa-spin"></i>
                                    <i *ngIf="!refreshing" class="fa fa-floppy-o"></i>&nbsp;&nbsp;
                                    <span *ngIf="refreshing">Chargement...</span>
                                    <span *ngIf="!refreshing">Sauvegarder</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </nz-card>
            </div>
        </div>
    </div>
</app-columnone>