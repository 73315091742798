import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { AddusergroupComponent } from '../addusergroup/addusergroup.component';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AdduserComponent implements OnInit {
  size: NzButtonSize = 'large';

  constructor(public modal: NgbActiveModal, private modalService: NgbModal, private router: Router) { }

  ngOnInit(): void {
  }

  public createNewGroupe(): void {
    const modalRef = this.modalService.open(AddusergroupComponent, { centered: true });
    modalRef.result.then(
      () => {
        this.modal.close('Ok click');
      },
      () => {
      });
  }

  public createNewIndividual(): void {
    this.router.navigateByUrl('/controlpanel/addnewuser');
    this.modal.close('Ok click');
  }

}
