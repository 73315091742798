import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent } from 'angular-calendar';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { CustomHttpRespone } from 'src/app/shared/model/custom-http-response';
import { HolidayService } from 'src/app/shared/service/holiday.service';
import { NotificationService } from 'src/app/shared/service/notification.service';

@Component({
  selector: 'app-deletecalendarevent',
  templateUrl: './deletecalendarevent.component.html',
  styleUrls: ['./deletecalendarevent.component.css']
})
export class DeletecalendareventComponent implements OnInit {
  @Input() eventToDelete: CalendarEvent;
  @Input() title: string;

  constructor(public modal: NgbActiveModal, private holidayService: HolidayService, private router: Router,
    public datepipe: DatePipe, private notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  onDeleteEvent() {
    this.holidayService.getCalendarEvents().subscribe(
      (response: CalendarEvent[]) => {
        for (let i = 0; i < response.length; i++) {
          if ((this.eventToDelete.title == response[i]['title']) &&
            (this.datepipe.transform(this.eventToDelete.start, 'dd/MM/yyyy') == this.datepipe.transform(response[i]['start'], 'dd/MM/yyyy')) &&
            (this.datepipe.transform(this.eventToDelete.end, 'dd/MM/yyyy') == this.datepipe.transform(response[i]['end'], 'dd/MM/yyyy'))) {
            let calendarEventId = Number(response[i]['id']);
            this.holidayService.deleteCalendarEvent(calendarEventId).subscribe(
              (response: CustomHttpRespone) => {
                this.sendNotification(NotificationType.SUCCESS, response.message);
                this.modal.close('Ok click');
                this.router.navigateByUrl('/controlpanel/politics');
              },
              (error: HttpErrorResponse) => {
                this.sendNotification(NotificationType.ERROR, error.error.message);
              }
            )
          }
        }
      },
      (errorResponse: HttpErrorResponse) => {
        this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
      }
    )
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

}
