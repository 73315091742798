import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent } from 'angular-calendar';
import { Subscription } from 'rxjs';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { HolidayService } from 'src/app/shared/service/holiday.service';
import { NotificationService } from 'src/app/shared/service/notification.service';

@Component({
  selector: 'app-addcalendarevent',
  templateUrl: './addcalendarevent.component.html',
  styleUrls: ['./addcalendarevent.component.css']
})
export class AddcalendareventComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(public modal: NgbActiveModal, private holidayService: HolidayService, private router: Router,
    public datepipe: DatePipe, private notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  public saveNewCalendarEvent(): void {
    this.clickButton('new-calendarEvent-save');
  }

  onAddNewCalendarEvent(newCalendarEventForm: NgForm) {
    const formData = this.holidayService.createHolidayFormData(null, newCalendarEventForm.value);
    formData.append('start', this.datepipe.transform(newCalendarEventForm.value['start'], 'dd/MM/yyyy'));
    formData.append('end', this.datepipe.transform(newCalendarEventForm.value['end'], 'dd/MM/yyyy'));
    formData.append('colorPrimary', newCalendarEventForm.value['colorPrimary']);
    formData.append('colorSecondary', newCalendarEventForm.value['colorSecondary']);
    this.subscriptions.push(
      this.holidayService.addCalendarEvent(formData).subscribe(
        (response: CalendarEvent) => {
          newCalendarEventForm.reset();
          this.sendNotification(NotificationType.SUCCESS, `${response.title} added successfully`);
          this.modal.close('Ok click');
          this.router.navigateByUrl('/controlpanel/politics');
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        }
      )
    );
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  private clickButton(buttonId: string): void {
    document.getElementById(buttonId).click();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
