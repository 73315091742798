import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Leave } from '../model/leave';

@Injectable({providedIn: 'root'})
export class LeaveService {
  private host = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getAllLeaves(): Observable<Leave[]> {
    return this.http.get<Leave[]>(`${this.host}/rest/all-leave/list`);
  }

  public getLeavesByReviewedBy(reviewedBy: string): Observable<Leave[]> {
    return this.http.get<Leave[]>(`${this.host}/rest/leave-by-reviewed/${reviewedBy}`);
  }

  public getLeavesByUser(username: string): Observable<Leave[]> {
    return this.http.get<Leave[]>(`${this.host}/rest/leave-by-user/${username}`);
  }

  public addLeave(formData: FormData): Observable<Leave> {
    return this.http.post<Leave>(`${this.host}/rest/apply-leave/add`, formData);
  }

  public approvedOrRejectedBySupervisor(formData: FormData): Observable<Leave> {
    return this.http.post<Leave>(`${this.host}/rest/approved-leave-supervisor/update`, formData);
  }

  public approvedOrRejectedByAdmin(formData: FormData): Observable<Leave> {
    return this.http.post<Leave>(`${this.host}/rest/approved-leave-admin/update`, formData);
  }

  public getSickDocument(leaveDetailsId: string) {
    return this.http.get<any>(`${this.host}/rest/document/${leaveDetailsId}`, { responseType: 'arraybuffer' as 'json' });
  }

  public createLeaveDataForm(currentId: number, leave: Leave, sickDocument: File): FormData {
    const formData = new FormData();
    formData.append('currentId', JSON.stringify(currentId));
    formData.append('leaveType', leave.leaveType);
    formData.append('message', leave.message);
    formData.append('sickDocument', sickDocument);
    return formData;
  }

}
