import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/shared/model/user';

@Component({
  selector: 'app-infouser',
  templateUrl: './infouser.component.html',
  styleUrls: ['./infouser.component.css']
})
export class InfouserComponent implements OnInit {
  @Input() selectedUser: User;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.selectedUser.managerUsername = this.selectedUser["manager"]["username"];
  }

}
