import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { HolidayType } from 'src/app/shared/model/holidaytype';
import { HolidayService } from 'src/app/shared/service/holiday.service';
import { NotificationService } from 'src/app/shared/service/notification.service';

@Component({
  selector: 'app-addholidaytype',
  templateUrl: './addholidaytype.component.html',
  styleUrls: ['./addholidaytype.component.css']
})
export class AddholidaytypeComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(public modal: NgbActiveModal, private holidayService: HolidayService, private router: Router,
    public datepipe: DatePipe, private notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  public saveNewHolidayType(): void {
    this.clickButton('new-holidayType-save');
  }

  onAddNewHolidayType(newHolidayTypeForm: NgForm) {
    const formData = this.holidayService.createHolidayTypeFormData(null, newHolidayTypeForm.value);
    this.subscriptions.push(
      this.holidayService.addHolidayType(formData).subscribe(
        (response: HolidayType) => {
          newHolidayTypeForm.reset();
          this.sendNotification(NotificationType.SUCCESS, `${response.name} added successfully`);
          this.modal.close('Ok click');
          this.router.navigateByUrl('/controlpanel/politics');
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        }
      )
    );
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  private clickButton(buttonId: string): void {
    document.getElementById(buttonId).click();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
