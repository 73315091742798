import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { City } from 'src/app/shared/model/city';
import { User } from 'src/app/shared/model/user';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { ResourceService } from 'src/app/shared/service/resource.service';
import { UserService } from 'src/app/shared/service/user.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.css']
})
export class EdituserComponent implements OnInit, OnDestroy {
  public editUser!: User;
  public currentUsername!: string;
  public profileImage!: File;
  public approvalDocument!: File;
  private subscriptions: Subscription[] = [];
  public refreshing!: boolean;
  public fileName!: string;
  public fileNameDoc!: string;
  public files: any;
  public managers!: User[];
  public cities!: City[];
  public color = '#8FD300';
  public active: boolean;
  readonly minAge = 18;
  maxDob!: Date;

  constructor(private route: ActivatedRoute, private router: Router, private userService: UserService,
    private notificationService: NotificationService, public datepipe: DatePipe, private resourceService: ResourceService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.currentUsername = params["username"];
      this.userService.getUserByUserName(this.currentUsername).subscribe(
        (response: User) => {
          this.editUser = response;
          this.editUser.managerUsername = response["manager"]["username"];
          this.editUser.cityName = response["city"]["name"];
          this.active = response['active'];
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        }
      )
    });
    const today = new Date();
    this.maxDob = new Date(
      today.getFullYear() - this.minAge,
      today.getMonth(),
      today.getDate()
    );
    this.getUsers(false);
    this.getCities(false);
  }

  public backToMenu() {
    this.router.navigateByUrl('/controlpanel/accessmanagement');
  }

  public getUsers(showNotification: boolean): void {
    this.refreshing = true;
    this.subscriptions.push(
      this.userService.getUsers().subscribe(
        (response: User[]) => {
          this.userService.addUsersToLocalCache(response);
          this.managers = response;
          this.refreshing = false;
          if (showNotification) {
            this.sendNotification(NotificationType.SUCCESS, `${response.length} user(s) loaded successfully.`);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.refreshing = false;
        }
      )
    );

  }

  public getCities(showNotification: boolean): void {
    this.refreshing = true;
    this.subscriptions.push(
      this.resourceService.getCities().subscribe(
        (response: City[]) => {
          this.cities = response;
          this.refreshing = false;
          if (showNotification) {
            this.sendNotification(NotificationType.SUCCESS, `${response.length} city(ies) loaded successfully.`);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.refreshing = false;
        }
      )
    );
  }

  //only number will be add
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  public onUpdateUser(): void {
    this.editUser['dateBirth'] = this.datepipe.transform(this.editUser['dateBirth'], 'dd/MM/yyyy')!;
    this.editUser['dateRecruitment'] = this.datepipe.transform(this.editUser['dateRecruitment'], 'dd/MM/yyyy')!;
    this.editUser['dateDeactivation'] = this.datepipe.transform(this.editUser['dateDeactivation'], 'dd/MM/yyyy')!;
    const formData = this.userService.createUserFormDate(this.currentUsername, this.editUser, this.profileImage, this.approvalDocument);
    this.subscriptions.push(
      this.userService.updateUser(formData).subscribe(
        (response: User) => {
          this.fileName = null!;
          this.fileNameDoc = null;
          this.profileImage = null!;
          this.approvalDocument = null;
          this.sendNotification(NotificationType.SUCCESS, `${response.firstName} ${response.lastName} updated successfully`);
          this.getUsers(false);
          this.router.navigateByUrl('/controlpanel/accessmanagement');
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.profileImage = null!;
          this.approvalDocument = null;
        }
      )
    );
  }

  public onProfileImageChange(event: any): void {
    const filesEvent = Object.keys(event.target.files).map((key: any) => event.target.files[key]);
    this.files = filesEvent[0];
    this.fileName = this.files.name;
    this.profileImage = this.files;
  }

  public onApprovalDocumentChange(event: any): void {
    const filesEvent = Object.keys(event.target.files).map((key: any) => event.target.files[key]);
    this.files = filesEvent[0];
    this.fileNameDoc = this.files.name;
    this.approvalDocument = this.files;
  }

  public isActive(): void { 
    this.active = this.editUser['active'];
  }

  public downloadFile(username: string, fileName: string): void {
    this.userService.downloadPdf(username, fileName + '.pdf').subscribe(blob => saveAs(blob, fileName + '.pdf'));
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
