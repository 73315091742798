<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Ajouter {{type}}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid h-100">
        <div class="d-flex h-100 p-v-15 flex-column justify-content-between">
            <div class="container">
                <div class="row align-items-center">
                    <form nz-form #newCountryForm="ngForm" (ngSubmit)="onAddNewCountry(newCountryForm)" nzLayout="vertical" class="login-form" [hidden]="!isCountry">
                        <nz-form-item>
                            <nz-form-label nzRequired nzFor="name">Nom</nz-form-label>
                            <nz-form-control nzErrorTip="The input is not valid name!">
                                <input type="text" nz-input placeholder="Name" id="name" name="name" ngModel>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label nzRequired nzFor="capital">Capitale</nz-form-label>
                            <nz-form-control nzErrorTip="The input is not valid capital!">
                                <input type="text" nz-input placeholder="Capital" id="capital" name="capital" ngModel>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label nzRequired nzFor="currency">Devise</nz-form-label>
                            <nz-form-control nzErrorTip="Please input your currency!">
                                <input type="text" nz-input placeholder="Currency" id="currency" name="currency" ngModel>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label nzRequired nzFor="phoneCode">Indicatif Tél.</nz-form-label>
                            <nz-form-control nzErrorTip="The input is not valid Code!">
                                <input type="text" nz-input placeholder="Phone code" id="phoneCode" name="phoneCode" ngModel>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label nzRequired nzFor="iso_2_code">Iso 2 code</nz-form-label>
                            <nz-form-control nzErrorTip="Please input your iso 2 code!">
                                <input type="text" nz-input placeholder="Iso 2 code" id="iso_2_code" name="iso_2_code" ngModel>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label nzRequired nzFor="nativeName">Nom d'origine</nz-form-label>
                            <nz-form-control nzErrorTip="The input is not valid native name!">
                                <input type="text" nz-input placeholder="Native name" id="nativeName" name="nativeName" ngModel>
                            </nz-form-control>
                        </nz-form-item>
                        <button type="submit" style="display: none;" id="new-country-save" nz-button></button>
                    </form>
                    <form nz-form #newCityForm="ngForm" (ngSubmit)="onAddNewCity(newCityForm)" nzLayout="vertical" class="login-form" [hidden]="!isCity">
                        <nz-form-item>
                            <nz-form-label nzRequired nzFor="name">Nom</nz-form-label>
                            <nz-form-control nzErrorTip="The input is not valid name!">
                                <input type="text" nz-input placeholder="Name" id="name" name="name" ngModel>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label nzRequired nzFor="code">Code</nz-form-label>
                            <nz-form-control nzErrorTip="The input is not valid code!">
                                <input type="text" nz-input placeholder="Code" id="code" name="code" ngModel>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label nzRequired nzFor="region">Région</nz-form-label>
                            <nz-form-control nzErrorTip="Please input your region!">
                                <input type="text" nz-input placeholder="region" id="region" name="region" ngModel>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label nzRequired nzFor="country">Pays</nz-form-label>
                            <nz-form-control nzErrorTip="Please input your country!">
                                <select class="form-select form-select-sm" [formControl]='countrySelected'>
                                        <option disabled [value]="null">Sélectionner un pays</option>
                                        <option *ngFor="let country of countries" [value]="country.name">
                                            {{ country.name }}
                                        </option>
                                    </select>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label nzRequired nzFor="nativeName">Nom d'origine</nz-form-label>
                            <nz-form-control nzErrorTip="The input is not valid native name!">
                                <input type="text" nz-input placeholder="Native name" id="nativeName" name="nativeName" ngModel>
                            </nz-form-control>
                        </nz-form-item>
                        <button type="submit" style="display: none;" id="new-city-save" nz-button></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Annuler</button>
    <button type="button" class="btn btn-green" (click)="saveNewCountry()" [hidden]="!isCountry">Créer</button>
    <button type="button" class="btn btn-green" (click)="saveNewCity()" [hidden]="!isCity">Créer</button>
</div>