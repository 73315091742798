import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { Role } from 'src/app/shared/enum/role.enum';
import { AdministraveRequest } from 'src/app/shared/model/administrativerequest';
import { User } from 'src/app/shared/model/user';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { PersonnelService } from 'src/app/shared/service/personnel.service';
import { EditadministrativerequestComponent } from '../modals/editadministrativerequest/editadministrativerequest.component';

const children: string[] = [
  "Attestation de travail",
  "Attestation de salaire",
  "Bulletin de paie",
  "Domiciliation de salaire",
  "Attestation de titularisation",
  "Bordereau CNSS",
  "Autres"
];

@Component({
  selector: 'app-administrativerequest',
  templateUrl: './administrativerequest.component.html',
  styleUrls: ['./administrativerequest.component.css']
})
export class AdministrativerequestComponent implements OnInit, OnDestroy {
  public refreshing!: boolean;
  public user!: User;
  private subscriptions: Subscription[] = [];
  public form!: FormGroup;
  public administrativeRequests: AdministraveRequest[];
  public objects: Array<{ value: string; label: string }> = [];
  public listOfSelectedValue: Array<string> = [];
  public submitted = false;

  displayedColumnsAdministrativeRequest: string[] = ['objects', 'users', 'emergency', 'status', 'actions'];
  public dataSourceAdministrativeRequest = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private personnelService: PersonnelService, private formBuilder: FormBuilder, private authenticationService: AuthenticationService,
    private modalService: NgbModal, private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      emergency: ['', Validators.required],
      message: ['', Validators.required]
    });
    this.user = this.authenticationService.getUserFromLocalCache();
    this.getAdministrativeRequests(true);
    this.objects = children.map(item => ({
      value: item,
      label: item
    }));
  }

  public ngAfterViewInit() {
    this.dataSourceAdministrativeRequest.paginator = this.paginator;
  }

  public applyFilterAdministrativeRequest(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceAdministrativeRequest.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceAdministrativeRequest.paginator) {
      this.dataSourceAdministrativeRequest.paginator.firstPage();
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  public onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    const formData = this.personnelService.createAdministraveRequestFormData(this.form.value);
    formData.append('username', this.user.username);
    formData.append('objectList', JSON.stringify(this.listOfSelectedValue));
    this.subscriptions.push(
      this.personnelService.addAdministraveRequest(formData).subscribe(
        (response: AdministraveRequest) => {
          this.form.reset();
          this.listOfSelectedValue = [];
          this.sendNotification(NotificationType.SUCCESS, `your request sent successfully`);
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        }
      )
    );
  }

  public getAdministrativeRequests(showNotification: boolean): void {
    this.refreshing = true;
    this.subscriptions.push(
      this.personnelService.getAdministrativeRequests().subscribe(
        (response: AdministraveRequest[]) => {
          this.administrativeRequests = [];
          for (let i = 0; i < response.length; i++) {
            let administraveRequestObj = {} as AdministraveRequest;
            administraveRequestObj['id'] = response[i]['id'];
            administraveRequestObj['objects'] = response[i]['objects'];
            administraveRequestObj['object'] = response[i]['objects'].join(" | ");
            administraveRequestObj['emergency'] = response[i]['emergency'];
            administraveRequestObj['message'] = response[i]['message'];
            administraveRequestObj['status'] = response[i]['status'];
            administraveRequestObj['users'] = response[i]['user']['firstName'] + " " + response[i]['user']['lastName'];
            administraveRequestObj['createAt'] = response[i]['createAt'];
            this.administrativeRequests.push(administraveRequestObj);
          }
          const administrativeRequests = this.administrativeRequests.map((obj) => {
            return { ...obj, date: new Date(obj.createAt) };
          });
          this.dataSourceAdministrativeRequest.data = administrativeRequests.sort(
            (objA, objB) => objB.date.getTime() - objA.date.getTime(),
          );
          this.refreshing = false;
          if (showNotification) {
            this.sendNotification(NotificationType.SUCCESS, `${response.length} administrative request(s) loaded successfully.`);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.refreshing = false;
        }
      )
    );
  }

  public onEditAdministrativeRequest(index: number): void {
    const modalRef = this.modalService.open(EditadministrativerequestComponent);
    modalRef.componentInstance.editRequest = this.dataSourceAdministrativeRequest.data[index];
    modalRef.componentInstance.currentId = this.dataSourceAdministrativeRequest.data[index]['id'];
    modalRef.result.then(
      () => {
        this.getAdministrativeRequests(false);
      },
      () => {
      });
  }

  public get isAdmin(): boolean {
    return this.getUserRole() === Role.ADMIN;
  }

  public get isSupervisor(): boolean {
    return this.isAdmin || this.getUserRole() === Role.SUPERVISOR;
  }

  public get isAdminOrSuperovisor(): boolean {
    return this.isAdmin || this.isSupervisor;
  }

  private getUserRole(): string {
    return this.authenticationService.getUserFromLocalCache().role;
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
