import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { City } from 'src/app/shared/model/city';
import { Country } from 'src/app/shared/model/country';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { ResourceService } from 'src/app/shared/service/resource.service';

@Component({
  selector: 'app-editcity',
  templateUrl: './editcity.component.html',
  styleUrls: ['./editcity.component.css']
})
export class EditcityComponent implements OnInit, OnDestroy {
  @Input() editCity: City;
  @Input() currentName: string;
  @Input() countries: Country[];
  private subscriptions: Subscription[] = [];
  public refreshing: boolean;
  public countrySelected: FormControl = new FormControl();

  constructor(public activeModal: NgbActiveModal, private resourceService: ResourceService, private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.editCity.countryName = this.editCity['country'].name;
  }

  public onUpdateCity(): void {
    this.editCity.countryName = this.countrySelected.value;
    const formData = this.resourceService.createCityFormData(this.currentName, this.editCity);
    formData.append('countryName', this.countrySelected.value);
    this.subscriptions.push(
      this.resourceService.updateCity(formData).subscribe(
        (response: City) => {
          this.sendNotification(NotificationType.SUCCESS, `${response.name} updated successfully`);
          this.activeModal.close('Ok click');
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        }
      )
    );
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
