<mat-toolbar>
    <mat-toolbar-row class="justify-content-between">
        <button mat-icon-button (click)="toggleSidebar()">
          <mat-icon>menu</mat-icon>
      </button>
        <div class="row mr-2 ml-auto">
            <ul class="row m-0 align-items-center">
                <li>
                    <app-user [user]="user$" (signOut)="signOut()" (userProfile)="userProfile()" (changePassword)="changePassword()"></app-user>
                </li>
            </ul>
        </div>
    </mat-toolbar-row>
</mat-toolbar>