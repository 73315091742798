import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent } from 'angular-calendar';
import { Subscription } from 'rxjs';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { HolidayService } from 'src/app/shared/service/holiday.service';
import { NotificationService } from 'src/app/shared/service/notification.service';

@Component({
  selector: 'app-editcalendarevent',
  templateUrl: './editcalendarevent.component.html',
  styleUrls: ['./editcalendarevent.component.css']
})
export class EditcalendareventComponent implements OnInit, OnDestroy {
  @Input() editCalendarEvent: CalendarEvent;
  @Input() currentId: number;
  private subscriptions: Subscription[] = [];
  public refreshing: boolean;

  constructor(public activeModal: NgbActiveModal, private holidayService: HolidayService, public datepipe: DatePipe, 
    private router: Router, private notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  public onUpdateCalendarEvent(): void {
    const formData = this.holidayService.createHolidayFormData(this.currentId, this.editCalendarEvent);
    formData.append('start', this.datepipe.transform(this.editCalendarEvent.start, 'dd/MM/yyyy'));
    formData.append('end', this.datepipe.transform(this.editCalendarEvent.end, 'dd/MM/yyyy'));
    formData.append('colorPrimary', this.editCalendarEvent.color.primary);
    formData.append('colorSecondary', this.editCalendarEvent.color.secondary);
    this.subscriptions.push(
      this.holidayService.updateCalendarEvent(formData).subscribe(
        (response: CalendarEvent) => {
          this.sendNotification(NotificationType.SUCCESS, `${response.title} added successfully`);
          this.activeModal.close('Ok click');
          this.router.navigateByUrl('/controlpanel/politics');
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        }
      )
    );
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
