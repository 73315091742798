<app-columnone>
    <!-- main app container -->
    <div class="card m-3">
        <h5 class="card-header">
            <i nz-icon nzTooltipTitle="Cliquez pour revenir" nzTooltipPlacement="top" [nzTooltipColor]="color" class="mr-1" style="font-size:1vw;" nzType="menu-fold" nzTheme="outline" nz-tooltip (click)="backToMenu()"></i> Créer un utilisateur</h5>
        <div class="card-body">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="form-row">
                    <div class="form-group col-4">
                        <label for="firstName">Prénom</label>
                        <input formControlName="firstName" id="firstName" type="text" class="form-control form-control-sm">
                        <div *ngIf="f['firstName'].touched && f['firstName'].invalid" class="alert alert-danger">
                            <div *ngIf="f['firstName'].errors && f['firstName'].errors['required']">Prénom est obligatoire.</div>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label for="lastName">Nom</label>
                        <input formControlName="lastName" id="lastName" type="text" class="form-control form-control-sm">
                        <div *ngIf="f['lastName'].touched && f['lastName'].invalid" class="alert alert-danger">
                            <div *ngIf="f['lastName'].errors && f['lastName'].errors['required']">Nom est obligatoire.</div>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label for="username">Nom d'utilisateur</label>
                        <input formControlName="username" id="username" type="text" class="form-control form-control-sm">
                        <div *ngIf="f['username'].touched && f['username'].invalid" class="alert alert-danger">
                            <div *ngIf="f['username'].errors && f['username'].errors['required']">Nom d'utilisateur est obligatoire.</div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-4">
                        <label for="email">Adresse Email</label>
                        <input formControlName="email" id="email" type="text" class="form-control form-control-sm">
                        <div *ngIf="f['email'].touched && f['email'].invalid" class="alert alert-danger">
                            <div *ngIf="f['email'].errors && f['email'].errors['required']">Adresse Email est obligatoire.</div>
                            <div *ngIf="f['email'].errors && f['email'].errors['email']">Veuillez saisir une adresse e-mail valide.</div>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label for="phoneNumber">Téléphone</label>
                        <input (keypress)="keyPress($event)" required type="text" formControlName="phoneNumber" class="form-control form-control-sm">
                        <div *ngIf="f['phoneNumber'].touched && f['phoneNumber'].invalid" class="alert alert-danger">
                            <div *ngIf="f['phoneNumber'].errors && f['phoneNumber'].errors['required']">Téléphone est obligatoire.</div>
                            <div *ngIf="f['phoneNumber'].errors && f['phoneNumber'].errors['pattern'] || f['phoneNumber'].errors && f['phoneNumber'].errors['maxlength'] || f['phoneNumber'].errors && f['phoneNumber'].errors['minlength']">Le numéro de téléphone doit comporter au moins 10 chiffres</div>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label for="fullNameEmergency">Nom et prénom du contact en cas d'urgence</label>
                        <input formControlName="fullNameEmergency" id="fullNameEmergency" type="text" class="form-control form-control-sm">
                        <div *ngIf="f['fullNameEmergency'].touched && f['fullNameEmergency'].invalid" class="alert alert-danger">
                            <div *ngIf="f['fullNameEmergency'].errors && f['fullNameEmergency'].errors['required']">Nom et prénom du contact en cas d'urgence est obligatoire.</div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-4">
                        <label for="phoneNumberEmergency">Téléphone en cas d'urgence</label>
                        <input required type="text" formControlName="phoneNumberEmergency" class="form-control form-control-sm">
                        <div *ngIf="f['phoneNumberEmergency'].touched && f['phoneNumberEmergency'].invalid" class="alert alert-danger">
                            <div *ngIf="f['phoneNumberEmergency'].errors && f['phoneNumberEmergency'].errors['required']">Téléphone en cas d'urgence est obligatoire.</div>
                            <div *ngIf="f['phoneNumberEmergency'].errors && f['phoneNumberEmergency'].errors['pattern'] || f['phoneNumberEmergency'].errors && f['phoneNumberEmergency'].errors['maxlength'] || f['phoneNumberEmergency'].errors && f['phoneNumberEmergency'].errors['minlength']">Le numéro de téléphone doit comporter au moins 10 chiffres</div>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label for="homeAddress">Adresse du domicile</label>
                        <input formControlName="homeAddress" id="homeAddress" type="text" class="form-control form-control-sm">
                        <div *ngIf="f['homeAddress'].touched && f['homeAddress'].invalid" class="alert alert-danger">
                            <div *ngIf="f['homeAddress'].errors && f['homeAddress'].errors['required']">Adresse du domicile est obligatoire.</div>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label for="statusMarital">Status matrimonial</label>
                        <select formControlName="statusMarital" id="statusMarital" class="form-select form-select-sm">
                            <option value="single">Célibataire</option>
                            <option value="married">Marié(e)</option>
                        </select>
                        <div *ngIf="f['statusMarital'].touched && f['statusMarital'].invalid" class="alert alert-danger">
                            <div *ngIf="f['statusMarital'].errors && f['statusMarital'].errors['required']">Status matrimonial est obligatoire.</div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-4 w-50" style="display: grid;">
                        <label for="managerUsername">Manager</label>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Choisir un manager" id="managerUsername" name="managerUsername" formControlName="managerUsername">
                            <nz-option *ngFor="let manager of managers" nzLabel="{{manager.username}}" nzValue="{{manager.username}}">
                            </nz-option>
                        </nz-select>
                        <div *ngIf="f['managerUsername'].touched && f['managerUsername'].invalid" class="alert alert-danger">
                            <div *ngIf="f['managerUsername'].errors && f['managerUsername'].errors['required']">Manager est obligatoire.</div>
                        </div>
                    </div>
                    <div class="form-group col-4 w-50" style="display: grid;">
                        <label for="dateBirth">Date de naissance</label>
                        <mat-form-field>
                            <input matInput formControlName="dateBirth" id="dateBirth" [matDatepicker]="picker" [max]="maxDob" />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <div *ngIf="f['dateBirth'].touched && f['dateBirth'].invalid" class="alert alert-danger">
                            <div *ngIf="f['dateBirth'].errors && f['dateBirth'].errors['required']">Date de naissance est obligatoire.</div>
                        </div>
                    </div>
                    <div class="form-group col-4 w-50" style="display: grid;">
                        <label for="dateRecruitment">Date de recructement</label>
                        <mat-form-field>
                            <input matInput formControlName="dateRecruitment" id="dateRecruitment" [matDatepicker]="dateRecruitmentPicker" />
                            <mat-datepicker-toggle matSuffix [for]="dateRecruitmentPicker"></mat-datepicker-toggle>
                            <mat-datepicker #dateRecruitmentPicker></mat-datepicker>
                        </mat-form-field>
                        <div *ngIf="f['dateRecruitment'].touched && f['dateRecruitment'].invalid" class="alert alert-danger">
                            <div *ngIf="f['dateRecruitment'].errors && f['dateRecruitment'].errors['required']">Date de recructement est obligatoire.</div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-4 w-50" style="display: grid;">
                        <label for="cityName">Ville</label>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Choisir une ville" id="cityName" name="cityName" formControlName="cityName">
                            <nz-option *ngFor="let city of cities" nzLabel="{{city.name}}" nzValue="{{city.name}}">
                            </nz-option>
                        </nz-select>
                        <div *ngIf="f['cityName'].touched && f['cityName'].invalid" class="alert alert-danger">
                            <div *ngIf="f['cityName'].errors && f['cityName'].errors['required']">Ville est obligatoire.</div>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label for="role">Rôle attribué à l'utilisateur</label>
                        <select formControlName="role" id="role" class="form-select form-select-sm">
                            <option value="ROLE_ADMIN">ADMIN</option>
                            <option value="ROLE_SUPERVISOR">SUPERVISOR</option>
                            <option value="ROLE_FIELD_WORKER">FIELD WORKER</option>
                        </select>
                        <div *ngIf="f['role'].touched && f['role'].invalid" class="alert alert-danger">
                            <div *ngIf="f['role'].errors && f['role'].errors['required']">Rôle est obligatoire.</div>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label for="statusEmployer">Status de l'employé</label>
                        <select formControlName="statusEmployer" id="statusEmployer" class="form-select form-select-sm">
                            <option value="active">Actif</option>
                            <option value="inactive">Inactif</option>
                        </select>
                        <div *ngIf="f['statusEmployer'].touched && f['statusEmployer'].invalid" class="alert alert-danger">
                            <div *ngIf="f['statusEmployer'].errors && f['statusEmployer'].errors['required']">Status de l'employé est obligatoire.</div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-4">
                        <label for="cin">Numéro CIN</label>
                        <input formControlName="cin" id="cin" type="text" class="form-control form-control-sm">
                        <div *ngIf="f['cin'].touched && f['cin'].invalid" class="alert alert-danger">
                            <div *ngIf="f['cin'].errors && f['cin'].errors['required']">Numéro CIN est obligatoire.</div>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label for="cnss">Numéro CNSS</label>
                        <input formControlName="cnss" id="cnss" type="text" class="form-control form-control-sm">
                        <div *ngIf="f['cnss'].touched && f['cnss'].invalid" class="alert alert-danger">
                            <div *ngIf="f['cnss'].errors && f['cnss'].errors['required']">Numéro CNSS est obligatoire.</div>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label for="registrationNumber">Numéro d'immatriculation</label>
                        <input formControlName="registrationNumber" id="registrationNumber" type="text" class="form-control form-control-sm">
                        <div *ngIf="f['registrationNumber'].touched && f['registrationNumber'].invalid" class="alert alert-danger">
                            <div *ngIf="f['registrationNumber'].errors && f['registrationNumber'].errors['required']">Numéro d'immatriculation est obligatoire.</div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-4">
                        <label for="incremental">Incrémental (Jours/Mois)</label>
                        <input formControlName="incremental" id="incremental" type="text" class="form-control form-control-sm">
                        <div *ngIf="f['incremental'].touched && f['incremental'].invalid" class="alert alert-danger">
                            <div *ngIf="f['incremental'].errors && f['incremental'].errors['required']">Incrémental est obligatoire.</div>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label for="numberDaysLeave">Nombre de jours de congé à importer</label>
                        <input formControlName="numberDaysLeave" id="numberDaysLeave" type="text" class="form-control form-control-sm">
                        <div *ngIf="f['numberDaysLeave'].touched && f['numberDaysLeave'].invalid" class="alert alert-danger">
                            <div *ngIf="f['numberDaysLeave'].errors && f['numberDaysLeave'].errors['required']">Nombre de jours de congé à importer est obligatoire.</div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-5">
                        <label for="approvaldocument">Importer le document d'approbation signé</label>
                        <div class="custom-file">
                            <input type="file" (change)="showData($event)" class="custom-file-input" (change)="onApprovalDocumentChange($event)" formControlName="approvaldocument" id="approvaldocument" accept=".pdf">
                            <label class="custom-file-label">
                                <span [hidden]="!fileName">{{fileName}}</span>
                                <span [hidden]="fileName">Choisir un fichier</span>
                            </label>
                            <label>Ce document atteste l'acceptation de l'utilisateur à utiliser cet outil pour demander un congé, suivre sa demande et consulter son solde de congés.</label>
                            <div *ngIf="f['approvaldocument'].touched && f['approvaldocument'].invalid" class="alert alert-danger">
                                <div *ngIf="f['approvaldocument'].errors && f['approvaldocument'].errors['required']">Importer le document d'approbation signé est obligatoire.</div>
                            </div>
                        </div>
                        <p class="mt-2" [hidden]="!fileName"><strong>Aperçu de document importé :</strong></p>
                        <object #pdfview [data]='' type="application/pdf"></object>
                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex justify-content-end">
                        <button class="btn btn-green mr-1" [disabled]="form.invalid" type="submit">
                            <i *ngIf="refreshing" class="fa fa-spinner fa-spin"></i>
                            <i *ngIf="!refreshing" class="fa fa-floppy-o"></i>&nbsp;&nbsp;
                            <span *ngIf="refreshing">chargement...</span>
                            <span *ngIf="!refreshing">Enregistrer</span>
                        </button>
                        <button class="btn btn-secondary" type="reset" (click)="onReset()">Annuler</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</app-columnone>