import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { User } from 'src/app/shared/model/user';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { UserService } from 'src/app/shared/service/user.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-addusergroup',
  templateUrl: './addusergroup.component.html',
  styleUrls: ['./addusergroup.component.css']
})
export class AddusergroupComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public fileName!: string | null;
  public excelFile!: File;
  public files: any;
  public refreshing!: boolean;
  public records: User[] = [];
  @ViewChild('inputFile') inputFile: ElementRef;
  public isExcelFile: boolean;
  private workBook: any;

  constructor(public modal: NgbActiveModal, private userService: UserService, private router: Router, private notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  public onExcelFileChange(evt: any): void {
    const target: DataTransfer = <DataTransfer>(evt.target);
    this.isExcelFile = !!target.files[0].name.match(/(.xls|.xlsx)/);
    if (target.files.length > 1) {
      this.inputFile.nativeElement.value = '';
    }
    if (this.isExcelFile) {
      let jsonData = null;
      const reader = new FileReader();
      const file = evt.target.files[0];
      this.fileName = file.name;
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        jsonData = this.workBook.SheetNames.reduce((initial: any, name: any) => {
          const sheet = this.workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        if (jsonData["Template"]) {
          this.records = jsonData["Template"];
        } else {
          this.sendNotification(NotificationType.ERROR, `Please rename the first sheet to "Template".`);
          this.fileReset();
        }
      }
      reader.readAsBinaryString(file);

      reader.onerror = function () {
        console.log('error is occured while reading file!');
      };
    } else {
      this.sendNotification(NotificationType.ERROR, `Please import valid .xlsx file.`);
      this.fileReset();
    }
  }

  private fileReset() {
    this.inputFile.nativeElement.value = "";
    this.fileName = null;
    this.records = [];
    this.isExcelFile = false;
  }

  public onUploadUsers(): void {
    for (let i = 0; i < this.records.length; i++) {
      this.userService.getUserByUserName(this.records[i]["username"]).subscribe(
        (response: User) => {
          if (response == null) {
            const formData = this.userService.createUserFormDateForExcel(null, this.records[i]);
            this.subscriptions.push(
              this.userService.addUser(formData).subscribe(
                (response: User) => {
                },
                (errorResponse: HttpErrorResponse) => {
                  this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
                }
              )
            );
          } else {
            const formData = this.userService.createUserFormDateForExcel(response["username"], this.records[i]);
            this.subscriptions.push(
              this.userService.updateUser(formData).subscribe(
                (response: User) => {
                },
                (errorResponse: HttpErrorResponse) => {
                  this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
                }
              )
            );
          }
          // this.sendNotification(NotificationType.SUCCESS, `Recharge cards added successfully`);
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        }
      )
    }
    this.modal.close('Ok click');
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  downloadFile() {
    let link = document.createElement("a");
    link.download = "users_template";
    link.href = "assets/excel/users_template.xlsx";
    link.click();
  }

}
