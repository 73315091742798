<app-columnone>
    <!-- administrative request table -->
    <div class="mat-elevation-z8 w-90 m-auto mt-5">
        <nav class="navbar navbar-light navbar-expand-md bg-gray justify-content-md-center justify-content-start">
            <div class="navbar-collapse collapse justify-content-between align-items-center w-100" id="collapsingNavbar2">
                <ul class="navbar-nav text-md-center text-left">
                    <p class="font-weight-bold" style="font-size: 16px;">Demandes en attente d'approbation</p>
                </ul>
                <ul class="nav navbar-nav flex-row justify-content-md-center justify-content-start flex-nowrap">
                    <!-- Search form -->
                    <form class="form-inline my-2 my-lg-0 justify-content-center m-1">
                        <input class="form-control form-control-sm mr-sm-2 m-1" type="search" placeholder="Search users..." aria-label="Search" #input (keyup)="applyFilter($event)">
                    </form>
                    <button type="button" class="btn btn-green m-1 btn-sm" (click)="getLeavesByReviewedBy(true)">
                        <i *ngIf="refreshing" class="fa fa-refresh fa-spin"></i>
                        <i *ngIf="!refreshing" class="fa fa-refresh"></i>
                    </button>
                </ul>
            </div>
        </nav>
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Nom & Prénom </th>
                <td mat-cell *matCellDef="let row" (click)="onSelectLeave(row)"> {{row.user.firstName}} {{row.user.lastName}} </td>
            </ng-container>

            <ng-container matColumnDef="leaveType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Type de congé </th>
                <td mat-cell *matCellDef="let row" (click)="onSelectLeave(row)"> {{row.leaveType}} </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Date </th>
                <td mat-cell *matCellDef="let row" (click)="onSelectLeave(row)"> Du {{row.dateFrom | date: 'dd/MM/yyyy'}} au {{row.dateTo | date: 'dd/MM/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="days">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Jours </th>
                <td mat-cell *matCellDef="let row" (click)="onSelectLeave(row)"> {{row.numberDays}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Actions </th>
                <td mat-cell *matCellDef="let i = index">
                    <i class="fa fa-check btn btn-green" (click)="onApproveDemand(this.paginator.pageIndex * this.paginator.pageSize + i)"></i>
                    <i class="fa fa-times btn btn-green ml-1" (click)="onRejectDemand(this.paginator.pageIndex * this.paginator.pageSize + i)"></i>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="7">Aucune donnée ne correspond à ce filtre: "{{input.value}}"</td>
            </tr>
        </table>

        <mat-paginator class="bg-gray" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of requests"></mat-paginator>
    </div>
</app-columnone>