<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Approuver la demande</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>Etes-vous sûr de vouloir accorder ce congé?</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Annuler</button>
    <button type="button" class="btn btn-green" ngbAutofocus (click)="onApproveEvent()">Approuver</button>
</div>