<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Suppression de l'utilisateur: {{ username }}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p><strong>Etes-vous sûr de vouloir supprimer: <span class="text-primary">{{ username }}</span> ?</strong></p>
    <p>Toutes les informations associées à cet utilisateur seront perdues.
        <span class="text-danger">Cette opération est irréversible!</span>
    </p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Annuler</button>
    <button type="button" class="btn btn-danger" ngbAutofocus (click)="onDeleteUser()">Supprimer</button>
</div>