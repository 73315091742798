<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Nouvel utilisateur</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-group" style="text-align: center;">
        <p>Comment souhaitez-vous créer le nouvel utilisateur ?</p>
        <button nz-button class="btn btn-green btn-lg" (click)="createNewGroupe()" [nzSize]="size" nzShape="round">
            <i nz-icon nzType="usergroup-add" nzTheme="outline"></i>
            Groupe
          </button>
        <button nz-button class="btn btn-green btn-lg" (click)="createNewIndividual()" [nzSize]="size" nzShape="round">
            <i nz-icon nzType="user-add" nzTheme="outline"></i>
            Individuel
          </button>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Annuler</button>
</div>