<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Editer le pays: {{editCountry?.name}}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="">
        <form #editCountryForm="ngForm">
            <div class="form-group">
                <label for="name">Nom</label>
                <input type="text" name="name" required [(ngModel)]="editCountry.name" class="form-control form-control-sm">
            </div>
            <div class="form-group">
                <label for="capital">Capitale</label>
                <input type="text" name="capital" required [(ngModel)]="editCountry.capital" class="form-control form-control-sm">
            </div>
            <div class="form-group">
                <label for="currency">Devise</label>
                <input type="text" name="currency" required [(ngModel)]="editCountry.currency" class="form-control form-control-sm">
            </div>
            <div class="form-group">
                <label for="phoneCode">Indicatif Tél.</label>
                <input type="text" name="phoneCode" required [(ngModel)]="editCountry.phoneCode" class="form-control form-control-sm">
            </div>
            <div class="form-group">
                <label for="iso_2_code">Iso 2 code</label>
                <input type="text" name="iso_2_code" required [(ngModel)]="editCountry.iso_2_code" class="form-control form-control-sm">
            </div>
            <div class="form-group">
                <label for="nativeName">Nom d'origine</label>
                <input type="text" name="nativeName" required [(ngModel)]="editCountry.nativeName" class="form-control form-control-sm">
            </div>
        </form>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Fermer</button>
    <button type="button" (click)="onUpdateCountry()" [disabled]="editCountryForm.invalid" class="btn btn-green"><i class="fa fa-floppy-o"></i>&nbsp;&nbsp; Enregistrer les changements</button>
</div>