import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './components/login/login.component';

import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { RouterModule } from '@angular/router';
import { ChangepasswordComponent } from './components/changepassword/changepassword.component';
import { RegisterComponent } from './components/register/register.component';

const antdModule= [
  NzFormModule,
  NzInputModule,
  NzButtonModule,
  NzCardModule,
  NzCheckboxModule,
  NzIconModule,
  NzToolTipModule
]

@NgModule({
  declarations: [LoginComponent, ResetpasswordComponent, ChangepasswordComponent, RegisterComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
    ...antdModule,
  ],
  exports: [
    LoginComponent
  ]
})
export class AuthenticationModule { }
