<app-columnone>
    <div class="m-2">
        <nav class="navbar navbar-expand-md breadcrumb">
            <div class="collapse navbar-collapse" id="navbarCollapse" style="background-color: #fafafa;">
                <div class="nav nav-pills ml-5">
                    <a class="nav-item nav-link breadcrumb-item" (click)="getHolidayTypes(false)" data-toggle="tab" href="#holidaysType">Types de jours off</a>
                    <a class="nav-item nav-link breadcrumb-item" data-toggle="tab" href="#holidays">Jours fériés</a>
                </div>
            </div>
        </nav>
    </div>

    <!-- main content -->
    <div class="tab-content mt-3" id="myTabContent">

        <!-- holidaysType card -->
        <div class="tab-pane fade show active" id="holidaysType">
            <div class="container bootstrap snippets bootdey">
                <button type="button" class="btn btn-green m-5" (click)="createNewHolidayType()"><i class="fa fa-plus"></i> Ajouter un type de congé</button>
                <div class="row">
                    <div class="col-md-4 col-sm-6 col-xs-12" *ngFor="let holidayType of holidayTypes">
                        <div class="mini-stat clearfix bg-holidayType rounded">
                            <span class="mini-stat-icon"><i class='{{ holidayType.icon }} fg-holidayType'></i></span>
                            <div class="mini-stat-info">
                                <span style="color: #707070;"> {{ holidayType.name }} </span>
                                <i class="fa fa-edit btn btn-outline-secondary mt-2" (click)="editHolidayType(holidayType)"></i>
                                <i class="fa fa-trash btn btn-outline-secondary mt-2 ml-1" (click)="deleteHolidayType(holidayType.name)"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- holidays table -->
        <div class="tab-pane fade show" id="holidays">
            <div class="row text-center">
                <div class="col-md-4">
                    <div class="btn-group">
                        <div class="btn btn-green" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                            Précédent
                        </div>
                        <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
                            Aujourd'hui
                        </div>
                        <div class="btn btn-green" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                            Prochain
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'fr' }}</h3>
                </div>
                <div class="col-md-4">
                    <div class="btn-group">
                        <div class="btn btn-green" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
                            Mois
                        </div>
                        <div class="btn btn-green" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
                            Semaine
                        </div>
                        <div class="btn btn-green" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
                            Jour
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div [ngSwitch]="view">
                <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" locale="fr" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)"
                    (eventTimesChanged)="eventTimesChanged($event)">
                </mwl-calendar-month-view>
                <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" locale="fr" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                </mwl-calendar-week-view>
                <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" locale="fr" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                </mwl-calendar-day-view>
            </div>

            <!-- Everything you see below is just for the demo, you don't need to include it in your app -->

            <br /><br /><br />

            <h3>
                Modifier les événements
                <button class="btn btn-green float-right" (click)="addEvent()">
                    Ajouter nouveau
                </button>
                <div class="clearfix"></div>
            </h3>

            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Titre</th>
                            <th>Couleur primaire</th>
                            <th>Couleur secondaire</th>
                            <th>Commence à</th>
                            <th>Fini à</th>
                            <th>Retirer</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let event of events">
                            <td>
                                <input [disabled]="true" type="text" class="form-control" [(ngModel)]="event.title" (keyup)="refresh.next()" />
                            </td>
                            <td>
                                <input [disabled]="true" type="color" [(ngModel)]="event.color.primary" (change)="refresh.next()" />
                            </td>
                            <td>
                                <input [disabled]="true" type="color" [(ngModel)]="event.color.secondary" (change)="refresh.next()" />
                            </td>
                            <td>
                                <input [disabled]="true" class="form-control" type="text" mwlFlatpickr [(ngModel)]="event.start" (ngModelChange)="refresh.next()" [altInput]="true" [convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Not set"
                                />
                            </td>
                            <td>
                                <input [disabled]="true" class="form-control" type="text" mwlFlatpickr [(ngModel)]="event.end" (ngModelChange)="refresh.next()" [altInput]="true" [convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Not set"
                                />
                            </td>
                            <td>
                                <button class="btn btn-danger" (click)="deleteEvent(event)">
                          Supprimer
                        </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <ng-template #modalContent let-close="close">
                <div class="modal-header">
                    <h5 class="modal-title">Event action occurred</h5>
                    <button type="button" class="close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    <div>
                        Action:
                        <pre>{{ modalData?.action }}</pre>
                    </div>
                    <div>
                        Event:
                        <pre>{{ modalData?.event | json }}</pre>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" (click)="close()">
                    OK
                  </button>
                </div>
            </ng-template>

        </div>

    </div>
</app-columnone>