import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { CustomHttpRespone } from 'src/app/shared/model/custom-http-response';
import { HolidayService } from 'src/app/shared/service/holiday.service';
import { NotificationService } from 'src/app/shared/service/notification.service';

@Component({
  selector: 'app-deleteholidaytype',
  templateUrl: './deleteholidaytype.component.html',
  styleUrls: ['./deleteholidaytype.component.css']
})
export class DeleteholidaytypeComponent implements OnInit {
  @Input() name: string;

  constructor(public modal: NgbActiveModal, private holidayService: HolidayService, private notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  onDeleteHolidayType() {
      this.holidayService.deleteHolidayType(this.name).subscribe(
        (response: CustomHttpRespone) => {
          this.sendNotification(NotificationType.SUCCESS, response.message);
          this.modal.close('Ok click');
        },
        (error: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, error.error.message);
        }
      )
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

}
