import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { City } from 'src/app/shared/model/city';
import { Country } from 'src/app/shared/model/country';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { ResourceService } from 'src/app/shared/service/resource.service';
import { AddresourceComponent } from '../modals/addresource/addresource.component';
import { DeleteresourceComponent } from '../modals/deleteresource/deleteresource.component';
import { EditcityComponent } from '../modals/editresource/editcity/editcity.component';
import { EditcountryComponent } from '../modals/editresource/editcountry/editcountry.component';

@Component({
  selector: 'app-ressources',
  templateUrl: './ressources.component.html',
  styleUrls: ['./ressources.component.css']
})
export class RessourcesComponent implements OnInit, OnDestroy {
  public countries: Country[];
  public country: Country;
  public cities: City[];
  public city: City;
  public refreshing: boolean;
  private subscriptions: Subscription[] = [];

  displayedColumnsCountry: string[] = ['name', 'capital', 'currency', 'phoneCode', 'iso_2_code', 'nativeName', 'actions'];
  public dataSourceCountry = new MatTableDataSource<any>();
  

  displayedColumnsCity: string[] = ['name', 'code', 'region', 'nativeName', 'country', 'actions'];
  public dataSourceCity = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private modalService: NgbModal, private resourceService: ResourceService, private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.getCountries(false);
    this.getCities(false);
  }

  ngAfterViewInit() {
    this.dataSourceCountry.paginator = this.paginator;
    this.dataSourceCity.paginator = this.paginator;
  }

  applyFilterCountry(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceCountry.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceCountry.paginator) {
      this.dataSourceCountry.paginator.firstPage();
    }
  }

  applyFilterCity(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceCity.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceCity.paginator) {
      this.dataSourceCity.paginator.firstPage();
    }
  }

  public getCountries(showNotification: boolean): void {
    this.refreshing = true;
    this.subscriptions.push(
      this.resourceService.getCountries().subscribe(
        (response: Country[]) => {
          this.countries = response;
          this.dataSourceCountry.data = response;
          this.refreshing = false;
          if (showNotification) {
            this.sendNotification(NotificationType.SUCCESS, `${response.length} country(ies) loaded successfully.`);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.refreshing = false;
        }
      )
    );
  }

  public createNewCountry(): void {
    const modalRef = this.modalService.open(AddresourceComponent);
    modalRef.componentInstance.type = 'Pays';
    modalRef.result.then(
      () => {
        this.getCountries(false);
      },
      () => {
      });
  }

  public onDeleteCountry(index: number): void {
    const modalRef = this.modalService.open(DeleteresourceComponent);
    modalRef.componentInstance.name = this.dataSourceCountry.data[index]['name'];
    modalRef.componentInstance.type = 'Pays';
    modalRef.result.then(
      () => {
        this.getCountries(false);
      },
      () => {
      });
  }

  public onEditCountry(index: number): void {
    const modalRef = this.modalService.open(EditcountryComponent);
    modalRef.componentInstance.editCountry = this.dataSourceCountry.data[index];
    modalRef.componentInstance.currentName = this.dataSourceCountry.data[index]['name'];
    modalRef.result.then(
      () => {
        this.getCountries(false);
      },
      () => {
      });
  }

  public createNewCity(): void {
    const modalRef = this.modalService.open(AddresourceComponent);
    modalRef.componentInstance.type = 'Ville';
    modalRef.componentInstance.countries = this.countries;
    modalRef.result.then(
      () => {
        this.getCities(false);
      },
      () => {
      });
  }

  public onDeleteCity(index: number): void {
    const modalRef = this.modalService.open(DeleteresourceComponent);
    modalRef.componentInstance.name = this.dataSourceCity.data[index]['name'];
    modalRef.componentInstance.type = 'Ville';
    modalRef.result.then(
      () => {
        this.getCities(false);
      },
      () => {
      });
  }

  public onEditCity(index: number): void {
    const modalRef = this.modalService.open(EditcityComponent);
    modalRef.componentInstance.editCity = this.dataSourceCity.data[index];
    modalRef.componentInstance.currentName = this.dataSourceCity.data[index]['name'];
    modalRef.componentInstance.countries = this.countries;
    modalRef.result.then(
      () => {
        this.getCities(false);
      },
      () => {
      });
  }

  public getCities(showNotification: boolean): void {
    this.refreshing = true;
    this.subscriptions.push(
      this.resourceService.getCities().subscribe(
        (response: City[]) => {
          this.cities = response;
          this.dataSourceCity.data = response;
          this.refreshing = false;
          if (showNotification) {
            this.sendNotification(NotificationType.SUCCESS, `${response.length} city(ies) loaded successfully.`);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.refreshing = false;
        }
      )
    );
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
