<app-columnone>
    <div class="m-5">

        <!-- add new administrative request -->
        <div class="container-fluid h-100" [hidden]="isAdmin">
            <div class="d-flex h-100 p-v-15 flex-column justify-content-between">
                <div>
                </div>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <nz-card class="border border-2 overflow-auto" style="height: 550px;">
                                <div class="m-4">
                                    <p class="font-weight-bold" style="font-size:1.2vw;">Faire une demande administrative</p>
                                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                                        <div class="form-group">
                                            <label for="objects">Objet de la demande</label>
                                            <nz-select nzMode="multiple" nzPlaceHolder="Please select" [nzOptions]="objects" [(ngModel)]="listOfSelectedValue" [ngModelOptions]="{standalone: true}"></nz-select>
                                        </div>
                                        <div class="form-group">
                                            <label for="emergency">Degré d'urgence</label>
                                            <select formControlName="emergency" id="emergency" class="form-select form-select-sm">
                                                <option value="Elevé">Elevé</option>
                                                <option value="Moyen">Moyen</option>
                                                <option value="Bas">Bas</option>
                                            </select>
                                            <div *ngIf="f['emergency'].touched && f['emergency'].invalid" class="alert alert-danger">
                                                <div *ngIf="f['emergency'].errors && f['emergency'].errors['required']">Degré d'urgence est obligatoire.</div>
                                            </div>
                                        </div>
                                        <div class="form-group green-border-focus">
                                            <label for="message">Message</label>
                                            <textarea class="form-control form-control-sm" formControlName="message" id="message" rows="6"></textarea>
                                            <div *ngIf="f['message'].touched && f['message'].invalid" class="alert alert-danger">
                                                <div *ngIf="f['message'].errors && f['message'].errors['required']">Message est obligatoire.</div>
                                            </div>
                                        </div>
                                        <div class="col d-flex justify-content-end">
                                            <button class="btn btn-green" type="submit">
                                                <i *ngIf="refreshing" class="fa fa-spinner fa-spin"></i>
                                                <i *ngIf="!refreshing" class="fa fa-floppy-o"></i>&nbsp;&nbsp;
                                                <span *ngIf="refreshing">Chargement...</span>
                                                <span *ngIf="!refreshing">Créer</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </nz-card>
                        </div>
                        <div class="offset-md-1 col-md-6 d-none d-md-block">
                            <img class="img-fluid" src="assets/images/administrativerequest.png" alt="">
                        </div>
                    </div>
                </div>
                <div>
                </div>
            </div>
        </div>

        <!-- administrative request table -->
        <div class="mat-elevation-z8 w-90 m-auto mt-5" [hidden]="!isAdmin">
            <nav class="navbar navbar-light navbar-expand-md bg-gray justify-content-md-center justify-content-start">
                <div class="navbar-collapse collapse justify-content-between align-items-center w-100" id="collapsingNavbar2">
                    <ul class="navbar-nav text-md-center text-left">
                    </ul>
                    <ul class="nav navbar-nav flex-row justify-content-md-center justify-content-start flex-nowrap">
                        <!-- Search form -->
                        <form class="form-inline my-2 my-lg-0 justify-content-center m-1">
                            <input class="form-control form-control-sm mr-sm-2 m-1" type="search" placeholder="Rechercher..." aria-label="Search" #input (keyup)="applyFilterAdministrativeRequest($event)">
                        </form>
                        <button type="button" class="btn btn-green m-1 btn-sm" (click)="getAdministrativeRequests(true)">
                        <i *ngIf="refreshing" class="fa fa-refresh fa-spin"></i><i *ngIf="!refreshing" class="fa fa-refresh"></i>
                  </button>
                    </ul>
                </div>
            </nav>
            <table mat-table [dataSource]="dataSourceAdministrativeRequest" matSort>

                <ng-container matColumnDef="objects">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Objet de la demande </th>
                    <td mat-cell *matCellDef="let row"> {{row.object}} </td>
                </ng-container>

                <ng-container matColumnDef="emergency">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Degré d'urgence </th>
                    <td mat-cell *matCellDef="let row"> {{row.emergency}} </td>
                </ng-container>

                <ng-container matColumnDef="users">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Utilisateur </th>
                    <td mat-cell *matCellDef="let row"> {{row.users}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Status </th>
                    <td mat-cell *matCellDef="let row">{{row.status}} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Actions </th>
                    <td mat-cell *matCellDef="let i = index">
                        <i class="fa fa-edit btn btn-green" (click)="onEditAdministrativeRequest(this.paginator.pageIndex * this.paginator.pageSize + i)"></i>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsAdministrativeRequest"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsAdministrativeRequest;"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="7">Aucune donnée ne correspond à ce filtre: "{{input.value}}"</td>
                </tr>
            </table>

            <mat-paginator class="bg-gray" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of requests"></mat-paginator>
        </div>

    </div>
</app-columnone>