import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AdministraveRequest } from '../model/administrativerequest';
import { CustomHttpRespone } from '../model/custom-http-response';

@Injectable({ providedIn: 'root' })
export class PersonnelService {
  private host = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public getAdministrativeRequests(): Observable<AdministraveRequest[]> {
    return this.http.get<AdministraveRequest[]>(`${this.host}/personnel/list`);
  }

  public addAdministraveRequest(formData: FormData): Observable<AdministraveRequest> {
    return this.http.post<AdministraveRequest>(`${this.host}/personnel/add`, formData);
  }

  public updateAdministraveRequest(formData: FormData): Observable<AdministraveRequest> {
    return this.http.post<AdministraveRequest>(`${this.host}/personnel/update`, formData);
  }

  public deleteAdministraveRequest(id: number): Observable<CustomHttpRespone> {
    return this.http.delete<CustomHttpRespone>(`${this.host}/personnel/delete/${id}`);
  }

  public createAdministraveRequestFormData(administraveRequest: AdministraveRequest): FormData {
    const formData = new FormData();
    formData.append('emergency', administraveRequest.emergency);
    formData.append('message', administraveRequest.message);
    return formData;
  }

}
