import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CustomHttpRespone } from '../model/custom-http-response';
import { HolidayType } from '../model/holidaytype';
import { CalendarEvent } from 'angular-calendar';

@Injectable({providedIn: 'root'})
export class HolidayService {
  private host = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getCalendarEvents(): Observable<CalendarEvent[]> {
    return this.http.get<CalendarEvent[]>(`${this.host}/holidayResource/holiday/list`);
  }

  public addCalendarEvent(formData: FormData): Observable<CalendarEvent> {
    return this.http.post<CalendarEvent>(`${this.host}/holidayResource/holiday/add`, formData);
  }

  public updateCalendarEvent(formData: FormData): Observable<CalendarEvent> {
    return this.http.post<CalendarEvent>(`${this.host}/holidayResource/holiday/update`, formData);
  }

  public deleteCalendarEvent(id: number): Observable<CustomHttpRespone> {
    return this.http.delete<CustomHttpRespone>(`${this.host}/holidayResource/holiday/delete/${id}`);
  }

  public createHolidayFormData(currentId: number, calendarEvent: CalendarEvent): FormData {
    const formData = new FormData();
    formData.append('currentId', JSON.stringify(currentId));
    formData.append('title', calendarEvent.title);
    return formData;
  }

  public getHolidayTypes(): Observable<HolidayType[]> {
    return this.http.get<HolidayType[]>(`${this.host}/holidayResource/holidayType/list`);
  }

  public addHolidayType(formData: FormData): Observable<HolidayType> {
    return this.http.post<HolidayType>(`${this.host}/holidayResource/holidayType/add`, formData);
  }

  public updateHolidayType(formData: FormData): Observable<HolidayType> {
    return this.http.post<HolidayType>(`${this.host}/holidayResource/holidayType/update`, formData);
  }

  public deleteHolidayType(name: string): Observable<CustomHttpRespone> {
    return this.http.delete<CustomHttpRespone>(`${this.host}/holidayResource/holidayType/delete/${name}`);
  }

  public createHolidayTypeFormData(currentName: string, holidayType: HolidayType): FormData {
    const formData = new FormData();
    formData.append('currentName', currentName);
    formData.append('name', holidayType.name);
    formData.append('icon', holidayType.icon);
    return formData;
  }

}
