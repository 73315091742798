<app-columnone>

    <!-- Tables Project -->
    <div class="m-3" [hidden]="isAdmin">
        <p style="font-size:1.1vw; color: #8bc34a;">
            {{ user?.firstName }} {{ user?.lastName }}
        </p>
        <p style="font-size:1.1vw; text-align: right; margin-top: -30px;">
            Balance: {{ user?.numberDaysLeave }} jours
        </p>

        <div nz-row [nzGutter]="8">
            <!-- Add New Leave  -->
            <div nz-col [nzSpan]="10">
                <nz-card class="border border-2 overflow-auto" style="height: 600px;">
                    <div class="m-4">
                        <p class="font-weight-bold" style="font-size:1.2vw;">Demander un congé</p>
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <label for="leaveType">Type de congé</label>
                                <select formControlName="leaveType" (change)="selectSick($event)" id="leaveType" class="form-select form-select-sm">
                                    <option disabled [value]="null">Choisir un type de congé</option>
                                    <option *ngFor="let holidayType of holidayTypes" [value]="holidayType.name">
                                        {{ holidayType.name }}
                                    </option>
                                </select>
                                <div *ngIf="f['leaveType'].touched && f['leaveType'].invalid" class="alert alert-danger">
                                    <div *ngIf="f['leaveType'].errors && f['leaveType'].errors['required']">Type de congé est obligatoire.</div>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="isSick">
                                <label for="sickDocument">Importer le certificat de maladie sous <strong style="color: red;"> format PDF </strong></label>
                                <div class="custom-file">
                                    <input type="file" (change)="showData($event)" class="custom-file-input" (change)="onSickDocumentChange($event)" formControlName="sickDocument" id="sickDocument">
                                    <label class="custom-file-label">
                                        <span [hidden]="!fileName">{{fileName}}</span>
                                        <span [hidden]="fileName">Choisir un fichier</span>
                                    </label>
                                </div>
                                <p class="mt-2" [hidden]="!fileName"><strong>Aperçu de document importé :</strong></p>
                                <object [hidden]="!fileName" #pdfview [data]='' type="application/pdf"></object>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-6">
                                    <label for="dateFrom">Commence à</label>
                                    <input type="text" mwlFlatpickr formControlName="dateFrom" id="dateFrom" (change)="getMinDate($event)" [altInput]="true" [convertModelValue]="true" [enableTime]="false" dateFormat="Y-m-dTH:i" altFormat="F j, Y" placeholder="Pas encore défini" class="form-control form-control-sm"
                                    />
                                    <div *ngIf="f['dateFrom'].touched && f['dateFrom'].invalid" class="alert alert-danger">
                                        <div *ngIf="f['dateFrom'].errors && f['dateFrom'].errors['required']">Date est obligatoire.</div>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label for="dateTo">Fini à</label>
                                    <input type="text" mwlFlatpickr formControlName="dateTo" id="dateTo" (change)="getTotalNumberOfDays($event)" [minDate]="dateMin" [altInput]="true" [convertModelValue]="true" [enableTime]="false" dateFormat="Y-m-dTH:i" altFormat="F j, Y" placeholder="Pas encore défini"
                                        class="form-control form-control-sm" />
                                    <div *ngIf="f['dateTo'].touched && f['dateTo'].invalid" class="alert alert-danger">
                                        <div *ngIf="f['dateTo'].errors && f['dateTo'].errors['required']">Date est obligatoire.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-6">
                                    <label for="totalNumberOfDays">Nombre total de jours</label>
                                    <!-- <input readonly="true" name="totalNumberOfDays" [(ngModel)]="totalNumberOfDays" type="text" [ngModelOptions]="{standalone: true}" class="form-control form-control-sm"> -->
                                    <nz-input-number name="totalNumberOfDays" [(ngModel)]="totalNumberOfDays" [nzMin]="totalNumberOfDaysMin" [nzMax]="totalNumberOfDaysMax" [nzStep]="0.5" [ngModelOptions]="{standalone: true}" class="form-control form-control-sm"></nz-input-number>
                                </div>
                            </div>
                            <div class="form-group green-border-focus">
                                <label for="message">Message (facultatif)</label>
                                <textarea class="form-control form-control-sm" formControlName="message" id="message" rows="6"></textarea>
                            </div>
                            <div class="col d-flex justify-content-end">
                                <button class="btn btn-green" type="submit">
                                    <i *ngIf="refreshing" class="fa fa-spinner fa-spin"></i>
                                    <i *ngIf="!refreshing" class="fa fa-floppy-o"></i>&nbsp;&nbsp;
                                    <span *ngIf="refreshing">Chargement...</span>
                                    <span *ngIf="!refreshing">Envoyer</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </nz-card>
            </div>
            <!-- Leaves By User -->
            <div nz-col [nzSpan]="14">
                <nz-card class="border border-2 overflow-auto" style="height: 600px;">
                    <div class="m-4">
                        <div class="mat-elevation-z8 w-90 m-auto mt-5">
                            <nav class="navbar navbar-light navbar-expand-md bg-gray justify-content-md-center justify-content-start">
                                <div class="navbar-collapse collapse justify-content-between align-items-center w-100" id="collapsingNavbar2">
                                    <ul class="navbar-nav text-md-center text-left">
                                        <p class="font-weight-bold" style="font-size: 16px;">Historique des congés</p>
                                    </ul>
                                    <ul class="nav navbar-nav flex-row justify-content-md-center justify-content-start flex-nowrap">
                                        <!-- Search form -->
                                        <form class="form-inline my-2 my-lg-0 justify-content-center m-1">
                                            <input class="form-control form-control-sm mr-sm-2 m-1" type="search" aria-label="Search" #input (keyup)="applyFilterUser($event)">
                                        </form>
                                        <button type="button" class="btn btn-green m-1 btn-sm" (click)="downloadLeavesByUser()">
                                            <i class="fa fa-download"></i>
                                        </button>
                                        <button type="button" class="btn btn-green m-1 btn-sm" (click)="getLeavesByUser(true)">
                                            <i *ngIf="refreshing" class="fa fa-refresh fa-spin"></i><i *ngIf="!refreshing" class="fa fa-refresh"></i>
                                        </button>
                                    </ul>
                                </div>
                            </nav>
                            <table mat-table [dataSource]="dataSourceUser" matSort>

                                <ng-container matColumnDef="leaveType">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Type de congé </th>
                                    <td mat-cell *matCellDef="let row" (click)="onSelectLeave(row)"> {{row.leaveType}} </td>
                                </ng-container>

                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Date </th>
                                    <td mat-cell *matCellDef="let row" (click)="onSelectLeave(row)"> Du {{row.dateFrom | date: 'dd/MM/yyyy'}} au {{row.dateTo | date: 'dd/MM/yyyy'}} </td>
                                </ng-container>

                                <ng-container matColumnDef="numberDays">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Jours </th>
                                    <td mat-cell *matCellDef="let row" (click)="onSelectLeave(row)"> {{row.numberDays}} </td>
                                </ng-container>

                                <ng-container matColumnDef="supervisor">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> <i class='fas fa-user-alt'></i> </th>
                                    <td mat-cell *matCellDef="let row" (click)="onSelectLeave(row)"> <i class={{row.supervisor}}></i> </td>
                                </ng-container>

                                <ng-container matColumnDef="admin">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> <i class='fas fa-user-shield'></i> </th>
                                    <td mat-cell *matCellDef="let row" (click)="onSelectLeave(row)"> <i class={{row.admin}}></i> </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumnsUser"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsUser;"></tr>

                                <!-- Row shown when there is no matching data. -->
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="7">Aucune donnée ne correspond à ce filtre: "{{input.value}}"</td>
                                </tr>
                            </table>

                            <mat-paginator class="bg-gray" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of leaves"></mat-paginator>
                        </div>
                    </div>
                </nz-card>
            </div>
        </div>
    </div>
    <div class="m-5" [hidden]="!isAdmin">
        <!-- all leaves table -->
        <div class="mat-elevation-z8 w-90 m-auto mt-5">
            <nav class="navbar navbar-light navbar-expand-md bg-gray justify-content-md-center justify-content-start">
                <div class="navbar-collapse collapse justify-content-between align-items-center w-100" id="collapsingNavbar2">
                    <ul class="navbar-nav text-md-center text-left">
                        <!-- <p class="font-weight-bold" style="font-size: 16px;">Historique des congés</p> -->
                        <form [formGroup]="form" (ngSubmit)="onSubmitFilter()" style="display: flex;">
                            <input type="text" formControlName="dateFrom" mwlFlatpickr (change)="getMinDate($event)" [altInput]="true" [convertModelValue]="true" [enableTime]="false" dateFormat="Y-m-dTH:i" altFormat="F j, Y" placeholder="Pas encore défini" class="form-control form-control-sm mr-sm-2 m-1"
                            />
                            <input type="text" formControlName="dateTo" mwlFlatpickr [minDate]="dateMin" [altInput]="true" [convertModelValue]="true" [enableTime]="false" dateFormat="Y-m-dTH:i" altFormat="F j, Y" placeholder="Pas encore défini" class="form-control form-control-sm mr-sm-2 m-1"
                            />
                            <select formControlName="leaveType" class="form-select form-select-sm mr-sm-2 m-1">
                            <option disabled [value]="null">Type de congé</option>
                            <option *ngFor="let holidayType of holidayTypes" [value]="holidayType.name">
                                {{ holidayType.name }}
                            </option>
                            </select>
                            <button type="button" class="btn btn-gold m-1 btn-sm" type="submit">
                                <i class="fa fa-filter"></i>
                            </button>
                        </form>
                    </ul>
                    <ul class="nav navbar-nav flex-row justify-content-md-center justify-content-start flex-nowrap">
                        <!-- Search form -->
                        <form class="form-inline my-2 my-lg-0 justify-content-center m-1">
                            <input class="form-control form-control-sm mr-sm-2 m-1" type="search" placeholder="Rechercher..." aria-label="Search" #input (keyup)="applyFilter($event)">
                        </form>
                        <button type="button" class="btn btn-green m-1 btn-sm" (click)="downloadAllLeaves()">
                            <i class="fa fa-download"></i>
                        </button>
                        <button type="button" class="btn btn-green m-1 btn-sm" (click)="getAllLeaves(true)">
                            <i *ngIf="refreshing" class="fa fa-refresh fa-spin"></i><i *ngIf="!refreshing" class="fa fa-refresh"></i>
                        </button>
                    </ul>
                </div>
            </nav>
            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="leaveType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Type de congé </th>
                    <td mat-cell *matCellDef="let row" (click)="onSelectLeave(row)"> {{row.leaveType}} </td>
                </ng-container>

                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Utilisateur </th>
                    <td mat-cell *matCellDef="let row" (click)="onSelectLeave(row)"> {{row.user}} </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Date </th>
                    <td mat-cell *matCellDef="let row" (click)="onSelectLeave(row)"> Du {{row.dateFrom | date: 'dd/MM/yyyy'}} au {{row.dateTo | date: 'dd/MM/yyyy'}} </td>
                </ng-container>

                <ng-container matColumnDef="numberDays">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Jours </th>
                    <td mat-cell *matCellDef="let row" (click)="onSelectLeave(row)"> {{row.numberDays}} </td>
                </ng-container>

                <ng-container matColumnDef="supervisor">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> <i class='fas fa-user-alt'></i> </th>
                    <td mat-cell *matCellDef="let row" (click)="onSelectLeave(row)"> <i class={{row.supervisor}}></i> </td>
                </ng-container>

                <ng-container matColumnDef="admin">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> <i class='fas fa-user-shield'></i> </th>
                    <td mat-cell *matCellDef="let row" (click)="onSelectLeave(row)"> <i class={{row.admin}}></i> </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="7">Aucune donnée ne correspond à ce filtre: "{{input.value}}"</td>
                </tr>
            </table>

            <mat-paginator class="bg-gray" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of leaves"></mat-paginator>
        </div>
    </div>
</app-columnone>