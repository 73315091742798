import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { CustomHttpRespone } from 'src/app/shared/model/custom-http-response';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { ResourceService } from 'src/app/shared/service/resource.service';

@Component({
  selector: 'app-deleteresource',
  templateUrl: './deleteresource.component.html',
  styleUrls: ['./deleteresource.component.css']
})
export class DeleteresourceComponent implements OnInit {
  @Input() name: string;
  @Input() code: string;
  @Input() type: string;

  constructor(public modal: NgbActiveModal, private resourceService: ResourceService, private notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  onDeleteResource() {
    if(this.type == "Pays"){
      this.resourceService.deleteCountry(this.name).subscribe(
        (response: CustomHttpRespone) => {
          this.sendNotification(NotificationType.SUCCESS, response.message);
          this.modal.close('Ok click');
        },
        (error: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, error.error.message);
        }
      )
    }else if(this.type == "Ville"){
      this.resourceService.deleteCity(this.name).subscribe(
        (response: CustomHttpRespone) => {
          this.sendNotification(NotificationType.SUCCESS, response.message);
          this.modal.close('Ok click');
        },
        (error: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, error.error.message);
        }
      )
    }
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

}
