import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Leave } from 'src/app/shared/model/leave';
import { LeaveService } from 'src/app/shared/service/leave.service';

@Component({
  selector: 'app-sick-documentviewer',
  templateUrl: './sick-documentviewer.component.html',
  styleUrls: ['./sick-documentviewer.component.css']
})
export class SickDocumentviewerComponent implements OnInit {
  @Input() selectedLeave: Leave;
  @ViewChild('pdfViewer') pdfViewer: ElementRef;

  constructor(public activeModal: NgbActiveModal, public leaveService: LeaveService) { }

  ngOnInit(): void {
    this.getFile(this.selectedLeave['leaveDetailsId'] + '.pdf');
  }

  public getFile(leaveDetailsId: string) {
    this.leaveService.getSickDocument(leaveDetailsId).subscribe((responseMessage) => {
      let file = new Blob([responseMessage], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(file);
      this.pdfViewer.nativeElement.data = fileURL;
    })
  }

}
