import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { FileUploadStatus } from 'src/app/shared/model/file-upload.status';
import { Report } from 'src/app/shared/model/report';
import { User } from 'src/app/shared/model/user';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { ReportService } from 'src/app/shared/service/report.service';
import { UserService } from 'src/app/shared/service/user.service';
import { DeletereportComponent } from '../modals/deletereport/deletereport.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit, OnDestroy {
  public refreshing!: boolean;
  public fileName!: string;
  public editFileName: string;
  public files: any;
  public reportDocument!: File;
  public editReportDocument!: File;
  private subscriptions: Subscription[] = [];
  public fileStatus = new FileUploadStatus();
  public participants: Array<{ value: string; label: string }> = [];
  public listOfSelectedValue: Array<string> = [];
  public listOfEditSelectedValue: Array<string> = [];
  public reports: Array<Report> = [];
  public editReport = new Report();
  public form!: FormGroup;
  public submitted = false;
  public isAddReport: boolean;
  public isEditReport: boolean;
  public color = '#8FD300';

  pdfContent: any;
  @ViewChild('pdfview') pdfview!: ElementRef;
  @ViewChild('pdfViewer') pdfViewer: ElementRef;

  displayedColumns: string[] = ['title', 'dateFrom', 'actions'];
  public dataSource = new MatTableDataSource<any>();

  constructor(private router: Router, private formBuilder: FormBuilder, private userService: UserService, private reportService: ReportService,
    private notificationService: NotificationService, public datepipe: DatePipe, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      reportDocument: ['', Validators.required],
      place: ['', Validators.required],
      dateFrom: ['', Validators.required],
      dateTo: ['', Validators.required]
    });
    this.getUsers(false);
    this.getReports(false);
    this.isAddReport = true;
    this.isEditReport = false;
  }

  public backToMenu() {
    this.isAddReport = true;
    this.isEditReport = false;
  }

  public getUsers(showNotification: boolean): void {
    this.refreshing = true;
    this.subscriptions.push(
      this.userService.getUsers().subscribe(
        (response: User[]) => {
          const children: string[] = [];
          for (let i = 0; i < response.length; i++) {
            children.push(response[i]['username']);
          }
          this.participants = children.map(item => ({
            value: item,
            label: item
          }));
          this.refreshing = false;
          if (showNotification) {
            this.sendNotification(NotificationType.SUCCESS, `${response.length} user(s) loaded successfully.`);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.refreshing = false;
        }
      )
    );

  }

  public getReports(showNotification: boolean): void {
    this.refreshing = true;
    this.subscriptions.push(
      this.reportService.getReports().subscribe(
        (response: Report[]) => {
          this.reports = response;
          const reports = response.map((obj) => {
            return { ...obj, date: new Date(obj.createdAt) };
          });
          this.dataSource.data = reports.sort(
            (objA, objB) => objB.date.getTime() - objA.date.getTime(),
          );
          this.refreshing = false;
          if (showNotification) {
            this.sendNotification(NotificationType.SUCCESS, `${response.length} report(s) loaded successfully.`);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.refreshing = false;
        }
      )
    );

  }

  public showData(event: any) {
    const filesEvent = Object.keys(event.target.files).map((key: any) => event.target.files[key]);
    this.files = filesEvent[0];
    this.fileName = this.files.name;
    this.getBase64(this.files).then(
      (data: any) => {
        this.pdfContent =
          URL.createObjectURL(this.b64toBlob(data.split("data:application/pdf;base64,")[1], 'application/pdf')) +
          '#toolbar=0&navpanes=0&scrollbar=0&view=FitH';

        this.pdfview.nativeElement.setAttribute('data', this.pdfContent);
      }
    );
  }

  public editShowData(event: any) {
    const filesEvent = Object.keys(event.target.files).map((key: any) => event.target.files[key]);
    this.files = filesEvent[0];
    this.editFileName = this.files.name;
    this.getBase64(this.files).then(
      (data: any) => {
        this.pdfContent =
          URL.createObjectURL(this.b64toBlob(data.split("data:application/pdf;base64,")[1], 'application/pdf')) +
          '#toolbar=0&navpanes=0&scrollbar=0&view=FitH';

        this.pdfViewer.nativeElement.setAttribute('data', this.pdfContent);
      }
    );
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  public onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    const formData = this.reportService.createReportFormData(null!, this.form.value, this.reportDocument);
    formData.append('dateFrom', this.datepipe.transform(this.form.value['dateFrom'], 'dd/MM/yyyy'));
    formData.append('dateTo', this.datepipe.transform(this.form.value['dateTo'], 'dd/MM/yyyy'));
    formData.append('participantNames', JSON.stringify(this.listOfSelectedValue));
    this.subscriptions.push(
      this.reportService.addReport(formData).subscribe(
        (response: Report) => {
          this.fileName = null!;
          this.form.reset();
          this.listOfSelectedValue = [];
          this.getReports(false);
          this.sendNotification(NotificationType.SUCCESS, `${response.title} added successfully`);
          this.router.navigateByUrl('/controlpanel/reports');
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        }
      )
    );
  }

  public onUpdate() {
    const formData = this.reportService.createReportFormData(this.editReport['id'], this.editReport, this.editReportDocument);
    formData.append('dateFrom', this.datepipe.transform(this.editReport['dateFrom'], 'dd/MM/yyyy'));
    formData.append('dateTo', this.datepipe.transform(this.editReport['dateTo'], 'dd/MM/yyyy'));
    formData.append('participantNames', JSON.stringify(this.listOfEditSelectedValue));
    this.subscriptions.push(
      this.reportService.updateReport(formData).subscribe(
        (response: Report) => {
          this.editFileName = null!;
          this.listOfEditSelectedValue = [];
          this.backToMenu();
          this.getReports(false);
          this.sendNotification(NotificationType.SUCCESS, `${response.title} added successfully`);
          this.router.navigateByUrl('/controlpanel/reports');
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        }
      )
    );
  }

  public onDeleteReport(index: number): void {
    const modalRef = this.modalService.open(DeletereportComponent);
    modalRef.componentInstance.reportToDelete = this.dataSource.data[index];
    modalRef.componentInstance.title = this.dataSource.data[index]['title'];
    modalRef.result.then(
      () => {
        this.getReports(false);
        this.backToMenu();
      },
      () => {
      });
  }

  public onEditReport(index: number): void {
    this.editReport = this.dataSource.data[index];
    this.listOfEditSelectedValue = this.dataSource.data[index]['users'];
    this.isAddReport = false;
    this.isEditReport = true;
    // this.getFile(this.dataSource.data[index]['reportId'] + '.pdf');
  }

  public onReportDocumentChange(event: any): void {
    const filesEvent = Object.keys(event.target.files).map((key: any) => event.target.files[key]);
    this.files = filesEvent[0];
    this.fileName = this.files.name;
    this.reportDocument = this.files;
  }

  public onEditReportDocumentChange(event: any): void {
    const filesEvent = Object.keys(event.target.files).map((key: any) => event.target.files[key]);
    this.files = filesEvent[0];
    this.editFileName = this.files.name;
    this.editReportDocument = this.files;
  }

  public getFile(reportid: string) {
    this.reportService.getPdf(reportid).subscribe((responseMessage) => {
      let file = new Blob([responseMessage], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(file);
      this.pdfViewer.nativeElement.data = fileURL;
    })
  }

  public downloadFile(reportId: string): void {
    this.reportService.downloadPdf(reportId + '.pdf').subscribe(blob => saveAs(blob, reportId + '.pdf'));
  }

  public onReset() {
    this.submitted = false;
    this.form.reset();
  }

  private b64toBlob(b64Data: any, contentType: any) {
    var byteCharacters = atob(b64Data);

    var byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      var slice = byteCharacters.slice(offset, offset + 512),
        byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  private getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}