import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CustomHttpRespone } from '../model/custom-http-response';
import { Report } from '../model/report';

@Injectable({ providedIn: 'root' })
export class ReportService {
  private host = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public getReports(): Observable<Report[]> {
    return this.http.get<Report[]>(`${this.host}/report/list`);
  }

  public addReport(formData: FormData): Observable<Report> {
    return this.http.post<Report>(`${this.host}/report/add`, formData);
  }

  public updateReport(formData: FormData): Observable<Report> {
    return this.http.post<Report>(`${this.host}/report/update`, formData);
  }

  public deleteReport(id: number): Observable<CustomHttpRespone> {
    return this.http.delete<CustomHttpRespone>(`${this.host}/report/delete/${id}`);
  }

  public getPdf(reportid: string) {
    return this.http.get<any>(`${this.host}/report/document/${reportid}`, { responseType: 'arraybuffer' as 'json' });
  }

  public downloadPdf(reportid: string | undefined): Observable<Blob> {
    return this.http.get(`${this.host}/report/download/document/${reportid}`, {responseType: 'blob'});
  }

  public createReportFormData(currentId: number, report: Report, reportDocument: File): FormData {
    const formData = new FormData();
    formData.append('currentId', JSON.stringify(currentId));
    formData.append('title', report.title);
    formData.append('place', report.place);
    formData.append('reportDocument', reportDocument);
    return formData;
  }

}
