export class Report {
    public id: number;
    public title: string;
    public place: string;
    public dateFrom: string;
    public dateTo: string;
    public reportDocumentUrl: string;
    public users: [];
    public reportId: string;
    public createdAt: Date;

    constructor() {
        this.id = 0;
        this.title = '';
        this.place = '';
        this.dateFrom = '';
        this.dateTo = '';
        this.reportDocumentUrl = '';
        this.users = [];
        this.reportId = '';
        this.createdAt = null;
    }
}