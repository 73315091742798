import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { City } from '../model/city';
import { Observable } from 'rxjs';
import { Country } from '../model/country';
import { CustomHttpRespone } from '../model/custom-http-response';

@Injectable({providedIn: 'root'})
export class ResourceService {
  private host = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getCities(): Observable<City[]> {
    return this.http.get<City[]>(`${this.host}/resource/city/list`);
  }

  public addCity(formData: FormData): Observable<City> {
    return this.http.post<City>(`${this.host}/resource/city/add`, formData);
  }

  public updateCity(formData: FormData): Observable<City> {
    return this.http.post<City>(`${this.host}/resource/city/update`, formData);
  }

  public deleteCity(name: string): Observable<CustomHttpRespone> {
    return this.http.delete<CustomHttpRespone>(`${this.host}/resource/city/delete/${name}`);
  }

  public createCityFormData(currentName: string, city: City): FormData {
    const formData = new FormData();
    formData.append('currentName', currentName);
    formData.append('name', city.name);
    formData.append('code', city.code);
    formData.append('nativeName', city.nativeName);
    formData.append('region', city.region);
    return formData;
  }

  public getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(`${this.host}/resource/country/list`);
  }

  public addCountry(formData: FormData): Observable<Country> {
    return this.http.post<Country>(`${this.host}/resource/country/add`, formData);
  }

  public updateCountry(formData: FormData): Observable<Country> {
    return this.http.post<Country>(`${this.host}/resource/country/update`, formData);
  }

  public deleteCountry(name: string): Observable<CustomHttpRespone> {
    return this.http.delete<CustomHttpRespone>(`${this.host}/resource/country/delete/${name}`);
  }

  public createCountryFormData(currentName: string, country: Country): FormData {
    const formData = new FormData();
    formData.append('currentName', currentName);
    formData.append('name', country.name);
    formData.append('currency', country.currency);
    formData.append('phoneCode', country.phoneCode);
    formData.append('nativeName', country.nativeName);
    formData.append('capital', country.capital);
    formData.append('iso_2_code', country.iso_2_code);
    return formData;
  }

}
