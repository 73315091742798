<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Editer le type de congé: {{editHolidayType?.name}}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="">
        <form #editHolidayTypeForm="ngForm">
            <div class="form-group">
                <label for="name">Nom</label>
                <input type="text" name="name" required [(ngModel)]="editHolidayType.name" class="form-control form-control-sm">
            </div>
            <div class="form-group">
                <label for="icon">Icône</label>
                <select name="icon" id="icon" class="form-select form-select-sm" [(ngModel)]="editHolidayType.icon">
                    <option value="fas fa-calendar">Calendar</option>
                    <option value="fas fa-baby-carriage">Baby Carriage</option>
                    <option value="fas fa-briefcase-medical">Briefcase Medical</option>
                </select>
            </div>
        </form>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Fermer</button>
    <button type="button" (click)="onUpdateCountry()" [disabled]="editHolidayTypeForm.invalid" class="btn btn-green"><i class="fa fa-floppy-o"></i>&nbsp;&nbsp;Enregistrer les changements</button>
</div>