<app-columnone>
    <div class="container p-3">
        <div class="col-md-6 offset-md-3 mt-7">
            <div class="card">
                <div class="card-header  font-weight-bold">
                    Changer le mot de passe
                </div>
                <div class="card">
                    <div class="card-body">
                        <form [formGroup]="formChangePassword" (submit)="submit()">
                            <div class="form-group">
                                <label for="oldPassword" [ngClass]="formChangePassword.controls['oldPassword'].invalid ? 'text-danger' : ''">Ancien mot de passe</label>
                                <input id="oldPassword" formControlName="oldPassword" type="password" class="form-control" [ngClass]="formChangePassword.controls['oldPassword'].invalid ? 'is-invalid' : ''">
                                <label class="text-danger" *ngIf="formChangePassword.controls['oldPassword'].hasError('required')">
                                Ancien mot de passe est obligatoire!
                            </label>
                            </div>
                            <div class="form-group">
                                <label for="password" [ngClass]="formChangePassword.controls['password'].invalid ? 'text-danger' : ''">Nouveau mot de passe</label>
                                <input id="password" formControlName="password" type="password" class="form-control" [ngClass]="formChangePassword.controls['password'].invalid ? 'is-invalid' : ''">
                                <label class="col" [ngClass]="formChangePassword.controls['password'].hasError('required') || formChangePassword.controls['password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                                <i class="material-icons">
                                    {{ formChangePassword.controls['password'].hasError('required') ||
                                formChangePassword.controls['password'].hasError('minlength') ? 'cancel' :
                                'check_circle' }}
                                </i>
                                Doit contenir au moins 8 caractères !
                            </label>
                                <label class="col" [ngClass]="formChangePassword.controls['password'].hasError('required') || formChangePassword.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                                <i class="material-icons">
                                    {{ formChangePassword.controls['password'].hasError('required') ||
                                formChangePassword.controls['password'].hasError('hasNumber') ? 'cancel' :
                                'check_circle' }}
                                </i>
                                Doit contenir au moins 1 chiffre !
                            </label>
                                <label class="col" [ngClass]="formChangePassword.controls['password'].hasError('required') || formChangePassword.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                                <i class="material-icons">
                                    {{ formChangePassword.controls['password'].hasError('required') ||
                                formChangePassword.controls['password'].hasError('hasCapitalCase') ? 'cancel' :
                                'check_circle' }}
                                </i>
                                Doit contenir au moins 1 en majuscule !
                            </label>
                                <label class="col" [ngClass]="formChangePassword.controls['password'].hasError('required') || formChangePassword.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                                <i class="material-icons">
                                    {{ formChangePassword.controls['password'].hasError('required') ||
                                formChangePassword.controls['password'].hasError('hasSmallCase') ? 'cancel' :
                                'check_circle' }}
                                </i>
                                Doit contenir au moins 1 lettre en petit boîtier !
                            </label>
                                <label class="col" [ngClass]="formChangePassword.controls['password'].hasError('required') || formChangePassword.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                                <i class="material-icons">
                                    {{ formChangePassword.controls['password'].hasError('required') ||
                                formChangePassword.controls['password'].hasError('hasSpecialCharacters') ? 'cancel' :
                                'check_circle' }}
                                </i>
                                Doit contenir au moins 1 caractère spécial !
                            </label>
                            </div>
                            <div class="form-group">
                                <label for="confirmPassword" [ngClass]="formChangePassword.controls['confirmPassword'].invalid ? 'text-danger' : ''">
                                Confirmer le nouveau mot de passe
                            </label>
                                <input id="confirmPassword" formControlName="confirmPassword" type="password" class="form-control" [ngClass]="formChangePassword.controls['confirmPassword'].invalid ? 'is-invalid' : ''">
                                <label class="text-danger" *ngIf="formChangePassword.controls['confirmPassword'].hasError('required')">
                                Mot de passe requis!
                            </label>
                                <label class="text-danger" *ngIf="formChangePassword.controls['confirmPassword'].hasError('NoPassswordMatch')">
                                Le mot de passe ne correspond pas
                            </label>
                            </div>
                            <div class="form-group">
                                <button [disabled]="formChangePassword.invalid" type="submit" class="btn btn-green btn-block">
                                Changer le mot de passe
                            </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</app-columnone>