<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Ajouter un jour férié</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid h-100">
        <div class="d-flex h-100 p-v-15 flex-column justify-content-between">
            <div class="container">
                <div class="row align-items-center">
                    <form nz-form #newCalendarEventForm="ngForm" (ngSubmit)="onAddNewCalendarEvent(newCalendarEventForm)" nzLayout="vertical" class="login-form">
                        <nz-form-item>
                            <nz-form-label nzRequired nzFor="title">Titre</nz-form-label>
                            <nz-form-control nzErrorTip="The input is not valid title!">
                                <input type="text" nz-input placeholder="Titre" id="title" name="title" ngModel>
                            </nz-form-control>
                        </nz-form-item>
                        <div class="row">
                            <div class="col">
                                <nz-form-item>
                                    <nz-form-label nzRequired nzFor="colorPrimary">Couleur primaire</nz-form-label>
                                    <nz-form-control nzErrorTip="The input is not valid color primary!">
                                        <input type="color" nz-input id="colorPrimary" name="colorPrimary" ngModel>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div class="col">
                                <nz-form-item>
                                    <nz-form-label nzRequired nzFor="colorSecondary">Couleur secondaire</nz-form-label>
                                    <nz-form-control nzErrorTip="The input is not valid color secondaire!">
                                        <input type="color" nz-input id="colorSecondary" name="colorSecondary" ngModel>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                        <nz-form-item>
                            <nz-form-label nzRequired nzFor="start">Commence à</nz-form-label>
                            <nz-form-control nzErrorTip="The input is not valid start!">
                                <input class="form-control form-control-sm" type="text" mwlFlatpickr ngModel name="start" [altInput]="true" [convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Pas encore défini" />
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label nzRequired nzFor="end">Fini à</nz-form-label>
                            <nz-form-control nzErrorTip="The input is not valid end!">
                                <input class="form-control form-control-sm" type="text" mwlFlatpickr ngModel name="end" [altInput]="true" [convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Pas encore défini" />
                            </nz-form-control>
                        </nz-form-item>
                        <button type="submit" style="display: none;" id="new-calendarEvent-save" nz-button></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Annuler</button>
    <button type="button" class="btn btn-green" [disabled]="newCalendarEventForm.invalid" (click)="saveNewCalendarEvent()">Créer</button>
</div>