<button class="button-img" [matMenuTriggerFor]="userMenu">
  <img height="50" width="50" style="border-radius: 35px;" src="{{user?.profileImageUrl}}" alt="">
</button>
<mat-menu #userMenu="matMenu" xPosition="before">
    <div class="user-menu-title">
        <h4 class="user-menu-title__name">{{user?.firstName}} {{user?.lastName}}</h4>
    </div>
    <button mat-menu-item class="user-menu__item-title" (click)="userProfileEmit()">
      <mat-icon class="user-menu-icon">person</mat-icon>Profil
    </button>
    <button mat-menu-item class="user-menu__item-title" (click)="changePasswordEmit()">
      <mat-icon class="user-menu-icon">lock</mat-icon>Mot de passe
    </button>
    <div class="sign-button-wrapper">
        <button class="sign-button" mat-flat-button (click)="signOutEmit()">Se déconnecter</button>
    </div>
</mat-menu>