<div class="container-fluid h-100">
    <div class="d-flex h-100 p-v-15 flex-column justify-content-between">
        <div>
        </div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-5">
                    <nz-card class="bg-border">
                        <h2 class="m-t-20">S'inscrire</h2>
                        <form [formGroup]="form" (ngSubmit)="onRegister()">
                            <div class="form-row">
                                <div class="form-group col-6">
                                    <label for="firstName">Prénom</label>
                                    <input formControlName="firstName" id="firstName" type="text" class="form-control form-control-sm">
                                    <div *ngIf="f['firstName'].touched && f['firstName'].invalid" class="alert alert-danger">
                                        <div *ngIf="f['firstName'].errors && f['firstName'].errors['required']">Prénom est obligatoire.</div>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label for="lastName">Nom</label>
                                    <input formControlName="lastName" id="lastName" type="text" class="form-control form-control-sm">
                                    <div *ngIf="f['lastName'].touched && f['lastName'].invalid" class="alert alert-danger">
                                        <div *ngIf="f['lastName'].errors && f['lastName'].errors['required']">Nom est obligatoire.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-6">
                                    <label for="username">Nom d'utilisateur</label>
                                    <input formControlName="username" id="username" type="text" class="form-control form-control-sm">
                                    <div *ngIf="f['username'].touched && f['username'].invalid" class="alert alert-danger">
                                        <div *ngIf="f['username'].errors && f['username'].errors['required']">Nom d'utilisateur est obligatoire.</div>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label for="email">Adresse Email</label>
                                    <input formControlName="email" id="email" type="text" class="form-control form-control-sm">
                                    <div *ngIf="f['email'].touched && f['email'].invalid" class="alert alert-danger">
                                        <div *ngIf="f['email'].errors && f['email'].errors['required']">Adresse Email est obligatoire.</div>
                                        <div *ngIf="f['email'].errors && f['email'].errors['email']">Veuillez saisir une adresse e-mail valide.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex justify-content-center links">
                                        Vous avez déjà un compte? <a routerLink="/login" class="ml-2" style="color: #0080ff;">Se connecter</a>
                                    </div>
                                    <button class="btn btn-green mr-1" [disabled]="form.invalid" type="submit">
                                        <i *ngIf="refreshing" class="fa fa-spinner fa-spin"></i>
                                        <i *ngIf="!refreshing" class="fa fa-floppy-o"></i>&nbsp;&nbsp;
                                        <span *ngIf="refreshing">chargement...</span>
                                        <span *ngIf="!refreshing">S'inscrire</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </nz-card>
                </div>
                <div class="offset-md-1 col-md-6 d-none d-md-block">
                    <img class="img-fluid" src="assets/images/login.png" alt="">
                </div>
            </div>
        </div>
        <div>
        </div>
    </div>
</div>